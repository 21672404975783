.rpcLocation {
    
    fill: #FBB901;
    fill-opacity: 1;
    fill-rule:"nonzero";
    stroke: #FFFFFF;
    stroke-width: 1px;
    display: inline-block;
    transition: all 0.1s ease-in-out;
}
.rpcLocation:hover {
    /* fill: #00ff22; */
    stroke-width: 3px;
    stroke: #FFFFFF;
    /* stroke-dasharray: 3,3; */
    /* transform: scale(1.2); */
    cursor: pointer;
}

.rpcLocationText {
    opacity: 0.7;
    transition: opacity 0.1s ease-in-out;
}

.rpcLocation:hover + .rpcLocationText {
    opacity: 1;
    
}
.rpcLocationText:hover {
    opacity: 1;
    cursor: pointer;
}

.rpcLocationText:hover + .rpcLocation {
    stroke-width: 3px;
    stroke: #FFFFFF;
    cursor: pointer;
}

.rpcLocationText .contentContainer {
    background-color: black;
    border: 2px solid transparent;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}
.rpcLocationText .contentContainer .locationText {
    color: white;
    font: 600 18px "Jost", sans-serif;
}
.rpcLocationText .contentContainer .rpcText {
    color: #ccc;
    font: 400 15px "Jost", sans-serif;
}
.rpcLocation:hover + .rpcLocationText .contentContainer {
    border: 2px solid #fff;
    
}
.rpcLocationText:hover .contentContainer {
    border: 2px solid #fff;
}

.map_container {
    padding-left: 80px;
    transform: scale(0.6);
    height: 770px;
}

@media screen and (max-width: 1440px) {
    .map_container {
        padding-left: 0px;
        transform: scale(0.50);
        height: 650px;
    }
}
@media screen and (max-width: 1366px) {
    .map_container {
        padding-left: 0px;
        transform: scale(0.47);
        height: 650px;
    }
}
@media screen and (max-width: 1280px) {
    .map_container {
        padding-left: 0px;
        transform: scale(0.43);
        height: 610px;
    }
}
@media screen and (max-width: 1152px) {
    .map_container {
        padding-left: 0px;
        transform: scale(0.40);
        height: 600px;
    }
}
@media screen and (max-width: 1024px) {
    .map_container {
        display: none;
    }
}
.map_radio_container {
    display: none;
}
@media screen and (max-width: 1024px) {
    .map_radio_container {
        display: block;
    }
}

.text_background_toggle {
    background-color: transparent;
    transition: background-color 0.2s ease;
}

.url_link_container {
    border: 2px solid;
    border-color: #000;
    transition: border-color 0.3s ease;
}