@import url("./fonts.css");
.no_decor {
  text-decoration: none !important;
}
.show_on_mobile
{
  display: none;
}

@media screen and (max-width: 767px) {
  .show_on_mobile
  {
    display: flex;
  }
  .show_on_desktop
  {
    display: none;
  }
}

.show_on_tab
{
  display: none;
}
.show_on_desktop_2
{
  display: block;
}
@media screen and (max-width: 992px) {
  .show_on_tab
  {
    display: flex;
  }
  .show_on_desktop_2
  {
    display: none;
  }
}

.blue_links
{
  background: -webkit-linear-gradient(270deg, #e0499b 2%, #f3dd59 98%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}
.white_links
{
  color: #fafafa;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}
.white_links:hover
{
  color: #fff;
  
}

.btn_solid_grad {
  display: inline-block;
  /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  min-width: 200px;
  border: none;
  border-radius: 2.5rem;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  color: #fff;
  font: 600 0.8rem/1.2rem "Poppins", sans-serif;
  
  text-align: center;
  text-decoration: none !important;
  transition: all 0.2s;
}

.btn_solid_grad:hover {
  transform: scale(1.1);
  color: #fff;
}

.btn_solid_grad.sm {
  display: inline-block;
  /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
  padding-top: 0.8rem;
  padding-bottom: 0.76rem;
  min-width: 150px;
  border: none;
  border-radius: 2.5rem;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  color: #fff;
  font: 600 0.8rem/1.2rem "Poppins", sans-serif;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.2s;
}

@media screen and (min-width:1366px) and (max-width:1600px) {
  .btn_solid_grad.sm {
    display: inline-block;
    /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
    padding-top: 0.5rem;
    padding-bottom: 0.55rem;
    min-width: 150px;
    border: none;
    border-radius: 2.5rem;
    background-color: rgba(89, 101, 243, 0.29);
    background: linear-gradient(
      90.8deg,
      rgba(224, 73, 155, 1) 3.34%,
      rgba(89, 101, 243, 1) 100%
    );
    color: #fff;
    font: 600 0.8rem/1.2rem "Poppins", sans-serif;
    text-align: center;
    text-decoration: none !important;
    transition: all 0.2s;
  }
}

.btn_solid_grad_full_width {
  display: inline-block;
  /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  border: none;
  border-radius: 2.5rem;
  background-color: #E33535;
  
  color: #fff;
  font: 600 0.8rem/1.2rem "Poppins", sans-serif;
  
  text-align: center;
  text-decoration: none !important;
  transition: all 0.2s;
}

.btn_solid_grad_full_width:hover {
  /* transform: scale(1.02); */
  color: #111 !important;
  background-color: #f4f4f4;
}
.btn_solid_grad_full_width.disabled
{
  opacity: 0.6;
}
.btn_solid_grad_full_width.success
{
  background: #50C878;
}
.btn_solid_grad_full_width.failed
{
  background: #D70040;
}
.btn_solid_grad_full_width.loading
{
  background: #FF9F00;
}
.btn_outline_gradient_button {
  display: inline-block;
  padding: 2px;
  border: none;
  border-radius: 2.5rem;
  min-width: 200px;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  font: 600 0.8rem/1.5rem "Poppins", sans-serif;
  letter-spacing: 0.1em;
  transition: all 0.2s;
}
.btn_outline_gradient_button .inner_white_area {
  padding: 0.58rem 0.2rem !important;
  background-color: #181818;
  border-radius: 2.5rem;
  transition: all 0.2s;
}
.btn_outline_gradient_button:hover {
  transform: scale(1.1);
}
a.btn_outline_gradient_button {
  display: inline-block;
  padding: 2px;
  border: none;
  border-radius: 2.5rem;
  min-width: 200px;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  font: 600 0.8rem/1.5rem "Poppins", sans-serif;
  letter-spacing: 0.1em;
  transition: all 0.2s;
  text-decoration: none;
  color: #fff;
}
a.btn_outline_gradient_button .inner_white_area {
  text-align: center;
  color: #fff;
}
a.btn_outline_gradient_button.sm {
  display: inline-block;
  padding: 2px;
  border: none;
  border-radius: 2.5rem;
  min-width: 160px;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  font: 600 0.8rem/1.2rem "Poppins", sans-serif;
  letter-spacing: 0.1em;
  transition: all 0.2s;
  text-decoration: none;
  color: #fff;
}
a.btn_outline_gradient_button.sm .inner_white_area {
  text-align: center;
  color: #fff;
}
.dark_button.btn_outline_gradient_button.sm {
  display: inline-block;
  padding: 2px;
  border: none;
  border-radius: 2.5rem;
  min-width: 160px;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  font: 600 0.8rem/1.2rem "Poppins", sans-serif;
  letter-spacing: 0.1em;
  transition: all 0.2s;
  text-decoration: none;
  color: #fff;
}
.dark_button.btn_outline_gradient_button .inner_white_area {
  padding: 0.58rem 0.2rem !important;
  background-color: #000;
  border-radius: 2.5rem;
  transition: all 0.2s;
}
.dark_button.btn_outline_gradient_button.sm .inner_white_area {
  text-align: center;
  color: #fff;
}

.background_black {
  background: url("../images/background-pattern.svg"),
    url("../images/second-webb.svg"), url("../images/background-pattern.svg"),
    linear-gradient(174.61deg, #111111 0%, #111111 99.2%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: 100% 80%, 0% 35%, 100% 8%, 0% 0%;
  background-size: 40%, 40%, 40%, auto;
}

.section_div {
  padding: 50px 2px;
}
.sub_section_div {
  padding: 20px -px;
}

.section_div_dark {
  padding: 20px 2px;
  background-color: #1F1F1F;
}
.small_keywords
{
  font: 600 14px/16px "Poppins", sans-serif;
  background: -webkit-linear-gradient(280deg, #e0499b 10%, #f3dd59 20%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
  padding-left: 3px;
}
.small_keywords_2
{
  font: 600 14px/16px "Poppins", sans-serif;
  background: -webkit-linear-gradient(280deg, #e0499b 5%, #f3dd59 15%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
  padding-left: 3px;
}
@media screen and (max-width: 1000px) {
  .small_keywords
  {
    font: 600 14px/16px "Poppins", sans-serif;
    background: -webkit-linear-gradient(280deg, #e0499b 15%, #f3dd59 55%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    
    padding-left: 3px;
  }
  .small_keywords_2
  {
    font: 600 14px/16px "Poppins", sans-serif;
    background: -webkit-linear-gradient(280deg, #e0499b 5%, #f3dd59 15%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    
    padding-left: 3px;
  }
}
.main_headings {
  font: 700 60px "Avenir-black", sans-serif;
  
  color: #fff;
}
.main_headings_sub {
  font: 700 48px "Avenir-black", sans-serif;
  
  color: #fff;
}

.p_para_1 {
  font: 300 18px "Poppins", sans-serif;
  
  color: #ffffffbb;
}
.p_para_1_sm {
  font: 300 15px "Poppins", sans-serif;
  
  color: #ffffffbb;
}

.p_para_2 {
  font: 300 14px/18px "Poppins", sans-serif;
  
  color: #ffffffdd;
}
.p_para_3 {
  font: 300 24px/28px "Poppins", sans-serif;
  
  color: #fff;
}
.dark_backdrop_gradient {
  background: linear-gradient(174.61deg, #111111 0%, #181818 99.2%);
}

.section_div_dark {
  padding: 20px 2px;
  background-color: #1F1F1F;
}
.row_reversal
{
  flex-direction: row-reverse;
}
.show_large
{
  display: block !important;
}
.show_small
{
  display: none !important;
}
@media screen and (max-width: 1000px) {
}
.get_api_key_image img
{
  display: block;
  margin: 0 auto !important;  
}


@media screen and (max-width: 767px) {
  html, body {
    overflow-x: hidden;
  }
  .main_headings {
    font: 700 40px "Avenir-black", sans-serif;
  }
  .main_headings_sub {
    font: 700 34px "Avenir-black", sans-serif;
    
    color: #fff;
  }

  .p_para_1 {
    font: 300 14px "Poppins", sans-serif;
  }
  .p_para_2 {
    font: 300 12px/16px "Poppins", sans-serif;
  }
  .main_landing_page .sub_section_div .p_para_trusted_by {
    font: 300 28px "Avenir-black", sans-serif !important;
    padding: 10px 10px !important;
  }
  .section_div {
    padding: 25px 10px !important;
    
  }
  .main_landing_page .first_section {
    padding: 80px 10px 40px !important;
  }
  .show_large
  {
    display: none !important;
  }
  .show_small
  {
    display: block !important;
  }
  .get_api_key_image
  {
    margin-top: -24px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .btn_solid_grad {
    min-width: 170px;
  }
  a.btn_outline_gradient_button
  {
    min-width: 170px;
  }
  .btn_solid_grad.sm
  {
    min-width: 140px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
  a.btn_outline_gradient_button.sm
  {
    min-width: 140px;
  }
}

/* Home Page Page Styles  */
.main_landing_page
{
  background: url("../images/background-pattern.svg"),
  url("../images/second-webb.svg"), url("../images/background-pattern.svg"),
  linear-gradient(174.61deg, #111111 0%, #111111 99.2%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: 100% 80%, 0% 35%, 100% 8%, 0% 0%;
  background-size: 40%, 40%, 40%, auto;
}
.main_landing_page .first_section {
  padding: 120px 0px 40px;
}
.main_landing_page .first_section .text_section .p_para_1 {
  text-align: justify;
  width: 94%;
}
.main_landing_page .first_section .btn_section {
  padding-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.main_landing_page .first_section .btn_section div {
  padding-right: 20px;
}
.main_landing_page .first_section .img_container
{
  padding: 20px 0px 32px !important;
}
@media screen and (min-width: 1000px) and (max-width: 1400px)
{
  .main_landing_page .first_section .cover_image_container img
  {
      width: 85%;
  } 
}



.gradient_name {
  font: 700 80px "Avenir-black", sans-serif;
  letter-spacing: -8px;
  background: -webkit-linear-gradient(1deg, #e0499b 0%, #5965f3 25%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.main_landing_page .sub_section_div .p_para_trusted_by {
  font: 300 40px "Avenir-black", sans-serif;
  
  color: #fff;
  padding: 20px 0px;
}
.trusted_icons_section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 10px; */
}
.trusted_icons_section div {
  padding: 20px 32px;
}

.main_landing_page .section_div {
  padding: 40px 0px;
}

.main_landing_page .trusted_icons_section {
  padding-bottom: 40px;
}
.main_landing_page .expensive_time_taking .why_us_info_box {
  padding: 64px 0px;
}

.main_landing_page .expensive_time_taking .why_box_outer {
  background: linear-gradient(
    102.4deg,
    #0aeeff -1.46%,
    #e0499b -1.45%,
    #5965f3 98.76%
  );
  width: 100%;
  /* max-width: 300px; */
  min-height: 160px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 1px;
}

.main_landing_page .expensive_time_taking .why_box_outer .why_box_inner {
  position: relative;
  width: 100%;
  min-height: 195px;
  border-radius: 15px;
  background-color: #111;
  color: #fff;
  padding: 32px 25px 10px;
}

.main_landing_page .expensive_time_taking .why_box_outer .why_box_inner h3 {
  font: 700 24px "Avenir-bold", sans-serif;
}

.main_landing_page
  .expensive_time_taking
  .why_box_outer
  .why_box_inner
  .tag_img {
  position: absolute;
  z-index: 3;
  top: -20px;
  left: -24px;
}

.main_landing_page
  .expensive_time_taking
  .why_box_outer
  .why_box_inner
  .tag_img
  img {
  width: 54px;
}

.whyus_cards_section {
  position: relative;
  display: flex;
  background: url("../images/why-us-shyft-logo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20%;
}
.whyus_cards_section .whyus_left_side {
  width: 50%;
  
}
.whyus_cards_section .whyus_icon_card_left {
  width: 100%;
  max-width: 400px;
  padding: 60px 30px;
  height: 150px;
}
.whyus_cards_section .whyus_icon_card_left:nth-child(odd)
{
  margin-left: 15%;
}
.whyus_cards_section .whyus_icon_card_left .whyus_icon_card_content {
  position: relative;
}
.whyus_cards_section .whyus_icon_card_left .whyus_cards_tag {
  position: absolute;
  top: -15px;
  left: 102%;
}
.whyus_cards_section .whyus_cards_tag img {
  width: 50px;
}
.whyus_cards_section .whyus_icon_card_left .whyus_icon_card_content h3 {
  font: 500 20px/24px "Poppins", sans-serif;
  text-align: right;
  color: #fff;
}
.whyus_cards_section .whyus_icon_card_left .whyus_icon_card_content p {
  font: 300 14px/18px "Poppins", sans-serif;
  text-align: right;
  color: #ffffffbb;
}



.whyus_cards_section .whyus_right_side {
  width: 50%;
  float: right;
}
.whyus_cards_section .whyus_icon_card_right {
  width: 100%;
  max-width: 550px;
  height: 150px;
  padding: 60px 50px;
  margin-left: 20%;
}
.whyus_cards_section .whyus_icon_card_right:nth-child(even)
{
  margin-left: 40%;
  max-width: 400px;
}
.whyus_cards_section .whyus_icon_card_right .whyus_icon_card_content {
  position: relative;
}
.whyus_cards_section .whyus_icon_card_right .whyus_cards_tag {
  position: absolute;
  top: -15px;
  left: -56px;
}
.whyus_cards_section .whyus_cards_tag img {
  width: 50px;
}
.whyus_cards_section .whyus_icon_card_right .whyus_icon_card_content h3 {
  font: 500 20px/24px "Poppins", sans-serif;
  text-align: left;
  color: #fff;
}
.whyus_cards_section .whyus_icon_card_right .whyus_icon_card_content p {
  font: 300 14px/18px "Poppins", sans-serif;
  text-align: justify;
  color: #ffffffbb;
}

.main_landing_page .project_cards_container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 0px;
  /* background: linear-gradient(90.8deg, #e0499b 3.34%, #5965f3 100%); */
  border-radius: 15px;
}
.main_landing_page .project_cards_container .inner {
  width: 100%;
  border-radius: 14px;
  padding: 6px;
  background-color: transparent;
}
.main_landing_page .project_card_spacer {
  padding-top: 24px;
}
.main_landing_page .project_cards_container .inner .project_text {
  font: 300 20px/24px "Poppins", sans-serif;
  
  color: #fff;
  text-align: center;
}
.main_landing_page .project_cards_container .inner .project_links {
  background: linear-gradient(180deg, #0aeeff 0%, #04adff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font: 600 16px/20px "Poppins", sans-serif;
  
}
.main_landing_page .project_cards_container .inner .project_link_set
{
  display: flex;
  justify-content: center;
}
.pricing_heading_container
{
  padding-bottom: 3rem;
}
.pricing_cards_container {
  padding: 10px 30px;
}
.pricing_cards {
  background: linear-gradient(
    135deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  border-radius: 17px;
  padding: 2px;
  min-height: 400px;
  position: relative;
}
.pricing_cards .pricing_cards_inner {
  background-color: #111111;
  border-radius: 17px;
  padding: 3px;
  height: 100%;
  padding-bottom: 20px;
}
.pricing_cards .pricing_cards_inner .height_custom_80 {
  height: 65%;
}
.pricing_cards .pricing_cards_inner .blue_band {
  background-color: #181818;
  padding: 10px 10px;
  padding-top: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.pricing_cards .pricing_cards_inner .blue_band h6 {
  font: 600 36px/48px "Montserrat", sans-serif;
  color: #fff !important;
  letter-spacing: 2px;
}
.pricing_cards .pricing_cards_inner .black_container {
  padding: 10px 10px;
  padding-top: 15px;
  background-color: transparent;
  border-radius: 12px;
  margin: 20px auto 40px;
  width: 90%;
}
.pricing_cards .pricing_cards_inner .black_container h6 {
  font: 600 1rem/1.2rem "Poppins", sans-serif;
  color: #000;
  
}
.pricing_cards .pricing_cards_inner .icon1_list_container {
  width: 90%;
  margin: 0 auto;
}
.pricing_cards .pricing_cards_inner .icon1_list_container .icon_list {
  border-bottom: 1px solid #ffffff66;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.pricing_cards .pricing_cards_inner .icon1_list_container .icon_list:last-of-type
{
  border-bottom: 1px solid transparent;
}
.pricing_cards
  .pricing-cards-inner
  .icon1-list-container
  .icon-list:last-child {
  border-bottom: none;
}
.pricing_cards .pricing_cards_inner .icon1_list_container h6 {
  font: 300 0.9rem/1.2rem "Poppins", sans-serif;
  
  padding-top: 7px;
  color: #fff;
}
.pricing_cards .pricing_cards_inner .icon1_list_container h6.custom_h6 {
  font: 400 0.9rem/1.6rem "Poppins", sans-serif;
}
.pricing_cards .btn_solid_grad
{
  width: 85% !important;
}

/* New Pricing introduced - Dec 2022 */
.pricing_gradient_outer
{
  background: linear-gradient(90.8deg, #E0499B 3.34%, #5965F3 100%);
  width: 100%;
  min-height: 100px;
  border-radius: 12px;
  padding: 1px;
}
.pricing_gradient_inner
{
  width: 100%;
  border-radius: 12px;
  min-height: 100px;
  background-color: #000;
  padding-top: 30px;
}
.meter_container
{
  padding: 36px 32px 20px 40px;
}
.meter_border
{
  border-right: 2px solid #151515;
}
.pricing_gradient_inner .thick_text
{
  font: 600 1.8rem/1.9rem "Poppins", sans-serif;
  color: #fff;
  
}
.pricing_gradient_inner .thin_text
{
  font: 500 1.3rem/1.5rem "Poppins", sans-serif;
  color: #fff;
  
}
.pricing_gradient_inner .xsmallest_text
{
  font: 300 0.9rem/1.0rem "Poppins", sans-serif;
  color: #fff;
  
  margin-top: 16px;
  /* margin-bottom: 12px; */
}
.pricing_gradient_inner .gradient_text_small
{
  font: 600 48px "Poppins", sans-serif;
  background: -webkit-linear-gradient(280deg, #E0499B 20%, #F3DD59 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricing_gradient_inner .gradient_text_xlarge
{
  font: 600 48px/44px "Poppins", sans-serif;
  margin-top: 16px;
  background: -webkit-linear-gradient(-30deg, #E0499B 20%, #F3DD59 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.divider_hr
{
  margin-bottom: 0px;
  border: 1px solid grey;
  margin-top: 20px;
}
.divider_hr_under
{
  margin-bottom: 10px;
  border: 1px solid grey;
  margin-top: 0px;
}
.pay_details
{
  margin-top: 32px;
  padding: 2px 52px;
}
.pay_details .thin_text_1
{
  font: 300 1.1rem/1.2rem "Poppins", sans-serif;
  color: #fff;
  
}
.pay_details .flex_capsules
{
  margin-top: 16px;
  margin-bottom: 48px;
}
.pay_details .flex_capsules .flex_capsule_pill
{
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  /* background: linear-gradient(170.8deg,#E0499B 3.34%,#F3DD59 100%); */
  margin-top: 12px;
  margin-right: 48px;
  border-radius: 25px;
  padding: 2px;

}
.pay_details .flex_capsules .flex_capsule_pill div
{
  border-radius: 25px;
  padding: 10px 24px;
  background-color: #111;
  font: 300 1rem/1.2rem "Poppins", sans-serif;
  color: #fffd;
}
.pay_compare
{
  padding: 10px 48px 20px;
}
.pay_compare .main_title
{
  font: 700 1.8rem/2.5rem "Poppins", sans-serif;
  color: #fff;
  
  
}
.pay_compare .em_points 
{
  margin-top: 8px;
}
.pay_compare .em_points .em_icon_container
{
  margin-right: 12px;
}
.pay_compare .em_points .em_text_container
{
  font: 300 1.0rem/1.8rem "Poppins", sans-serif;
  color: #fffd;
  
}
.pay_compare .em_btn
{
  display: inline-block;
  /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  min-width: 160px;
  border: none;
  border-radius: 2.5rem;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  color: #fff;
  font: 600 0.7rem/0.8rem "Poppins", sans-serif;
  
  text-align: center;
  text-decoration: none !important;
  margin-top: 6px;
  transition: all 0.2s;
}
.pay_compare .em_btn:hover
{
  transform: scale(1.05);
}
.enterprise_plans
{
  margin-top: 40px;
  margin-bottom: 54px;
}
.enterprise_plans .dashboard_main_title
{
  color: #fff;
}
.meter_container_master
{
  padding: 52px 64px 20px;
}
.only_meter
{
  position: relative;
}
.bubble {
  background: linear-gradient(90.8deg, #E0499B 3.34%, #5965F3 100%);
  /* padding: 6px 12px 4px; */
  padding: 2px;
  height: 39px;
  width: 39px;
  position: absolute;
  border-radius: 20px;
  top: -46px;
  left: 0.8%;
  transform: translateX(-50%);
  opacity: 1.0;
  transition: opacity 0.2s;
}
.bubble::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  top: 42px;
  left: 48%;
}
.bubble .bubble_text
{
  position: absolute;
  top: -34px;
  left: -18px;
  width: 100px;
  font: 500 0.7rem/0.9rem "Poppins", sans-serif;
  color: #fff;
  
  font-style: italic;
  opacity: 1.0;
  transition: opacity 0.25s;
}
.bubble div
{
  font: 400 0.69rem/0.9rem "Poppins", sans-serif;
  color: white;
  height: 100%;
  width: 100%;
  background-color: #000;
  border-radius: 20px;
  padding-top: 10px;
  text-align: center;
  border: 1px solid white;
}
.meter_container_estimated
{
  position: relative;
  margin-top: 34px;
}
.meter_container_estimated:last-of-type
{
  margin-top: 66px;
}
.meter_container_estimated .info_popup_empty
{
  height: 18px;
  width: 15px;
  margin-left: 16px;
  
}
.meter_container_estimated .info_popup
{
  height: 18px;
  width: 15px;
  /* background-color: #04ADFF; */
  margin-left: 16px;
}
.meter_container_estimated .info_popup .icon
{
  position: relative;
}
.meter_container_estimated .info_popup .icon img
{
  margin-top: -6px;
  width: 20px;
}
.meter_container_estimated .info_popup .message
{
  position: absolute;
  border: 1px solid white;
  width: 120px;
  margin-left: -90px;
  border-radius: 4px;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.meter_container_estimated .info_popup .message div
{
  position: relative;
  font: 300 0.7rem/0.9rem "Poppins", sans-serif;
  color: #fff;
  padding: 8px 10px;
  text-align: center;
}
.meter_container_estimated .info_popup .icon:hover + .message
{
  opacity: 1;
}
@media screen and (max-width: 1366px) {
  
  .meter_container_master
  {
    padding: 52px 24px 20px;
  }

  .pricing_gradient_inner .thin_text
  {
    font: 500 1.1rem/1.5rem "Poppins", sans-serif;
    
  }
  .pricing_gradient_inner .gradient_text_xlarge
  {
    font: 600 42px/44px "Poppins", sans-serif;
    margin-top: 8px;
  }
  .pricing_gradient_inner .gradient_text_small
  {
    font: 600 42px "Poppins", sans-serif;
  }
  .pay_compare .em_points 
  {
    margin-top: 8px;
  }
  .pay_compare .em_points .em_icon_container
  {
    margin-right: 12px;
  }
  .pay_compare .em_points .em_icon_container img
  {
    width: 14px;
  }
  .pay_compare .em_points .em_text_container
  {
    font: 300 0.9rem/1.8rem "Poppins", sans-serif;
    color: #fffd;
    
  }
  
}
@media screen and (max-width: 1200px)
{
  .meter_container
  {
    padding: 24px 30px 16px;
  }
  .meter_container_master
  {
    padding: 46px 30px 16px;
  }
  .pay_details
  {
    margin-top: 20px;
    padding: 2px 30px;
  }
  .pay_compare
  {
    padding: 10px 30px;
  }
  .pricing_gradient_inner .thick_text
  {
    font: 600 1.6rem/1.9rem "Poppins", sans-serif;
    color: #fff;
  }
  .meter_container_estimated
  {
    padding-top: 2px;
    margin-top: 32px;
  }
  
  .meter_container_estimated:last-of-type
  {
    margin-top: 28px;
    margin-bottom: 36px;
  }
  .pricing_gradient_inner .thin_text
  {
    font: 300 0.9rem/1.2rem "Poppins", sans-serif;
    color: #fff;
    
    margin-bottom: 9px;
    margin-top: 1%;
  }
  .pricing_gradient_inner .gradient_text_xlarge
  {
    font: 600 32px/36px "Poppins", sans-serif;
    margin-top: 0px;
  }
  .bubble .bubble_text
  {
    position: absolute;
    top: -28px;
    left: -14px;
    width: 100px;
    font: 500 0.6rem/0.8rem "Poppins", sans-serif;
    color: #fff;
    
    font-style: italic;
    opacity: 1.0;
    transition: opacity 0.25s;
  }
  .meter_container_estimated .info_popup .icon img
  {
    margin-top: -6px;
    width: 15px;
  }
  .pay_details .flex_capsules .flex_capsule_pill div
  {
    font: 300 0.8rem/1.0rem "Poppins", sans-serif;
    padding: 10px 16px;
  }
  
  
}
@media screen and (max-width: 1000px)
{
  .meter_container_estimated
  {
    margin-top: 10px;
  }
  .pricing_gradient_inner .gradient_text_xlarge
  {
    font: 600 40px/48px "Poppins", sans-serif;
    margin-top: 0px;
  }
  .pricing_gradient_inner .thin_text
  {
    font: 400 1.0rem/1.2rem "Poppins", sans-serif;
    
  }
  .pricing_gradient_inner .gradient_text_small
  {
    font: 600 42px "Poppins", sans-serif;
  }
  .pricing_gradient_inner .thick_text
  {
    font: 600 1.3rem/1.9rem "Poppins", sans-serif;
    color: #fff;
  }
  
}
@media screen and (max-width: 767px) {
  .pricing_gradient_inner .thin_text
  {
    margin-top: 2px;
    
  }
  .pay_compare .em_points .em_icon_container
  {
    margin-top: -1px;
  }
  .pay_compare .em_points .em_icon_container img
  {
    width: 16px;
  }
  .pay_details .thin_text_1
  {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .pay_details .flex_capsules .flex_capsule_pill div
  {
    font: 300 0.8rem/1.1rem "Poppins", sans-serif;
    padding: 8px 16px;
  }
  .pay_compare .em_points .em_text_container
  {
    font: 300 0.9rem/1.5rem "Poppins", sans-serif;
  }
  .pricing_gradient_inner .thick_text
  {
    font: 600 1.4rem/1.9rem "Poppins", sans-serif;
    color: #fff;
  }
  .meter_container
  {
    padding: 24px 16px 16px;
  }
  .meter_container_master
  {
    padding: 46px 16px 16px;
  }
  .pay_details
  {
    margin-top: 20px;
    padding: 2px 16px;
  }
  .pay_compare
  {
    padding: 4px 16px;
  }
  .enterprise_plans
  {
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .pricing_gradient_inner .gradient_text_xlarge
  {
    margin-top: -2px;
    margin-bottom: 30px;
    font: 600 44px/50px "Poppins", sans-serif;
  }
  /* .bubble
  {
    opacity: 1.0;
  } */
}
/* New Pricing introduced - Dec 2022 */

.community_section
{
    padding-bottom: 40px !important;
    padding-top: 10px !important;
}
.community-box {
    margin-top: 10px;
    padding: 20px 0px;
}

.community_box .icon_boxx .data {
    font: 700 24px "Avenir-bold", sans-serif;
    padding-top: 2px;
    color: #fff;
    padding-left: 10px;
    
    
}


.community_box .icon_boxx {
    margin-bottom: 16px;
}

.community_box .p_para_1 {
    padding-top: 10px;
}

.icon_boxx .data {
    font: 400 16px "Poppins", sans-serif;
    padding-top: 4px;
    color: #fff;
    padding-left: 10px;
    
}

.icon_boxx {
    margin-bottom: 16px;
}

.main_landing_page .tabbed_section
{
  background-color: #222;
  border-radius: 32px;
  padding: 8px 0px;
}
.main_landing_page .tabbed_section .tabbed_item .tabbed_link
{
  
  font: 400 18px "Poppins", sans-serif;
  color: #fff;
  transition: all 0.2s ease;
}
.main_landing_page .tabbed_section .tabbed_item .tabbed_link.active
{
  
  font: 700 18px "Poppins", sans-serif;
  background: -webkit-linear-gradient(-30deg, #E0499B 20%, #F3DD59 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.main_landing_page .tabbed_content
{
  padding: 20px 0px;
  padding-top: 40px;
}
.main_landing_page .tabbed_content .tabbed_text
{
  padding: 30px 0px;
}
.main_landing_page .tabbed_content .tabbed_text .pill_section 
{
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 60px;
}
.main_landing_page .tabbed_content .tabbed_text .pill_section .each_pill
{
  padding: 8px 20px;
  border-radius: 24px;
  background-color: #222;
  font: 400 14px "Poppins", sans-serif;
  
  color: #ffffffbb;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 9px;
  min-width: 180px;
  text-align: center;
}
.main_landing_page .tabbed_content h5
{
  font: 700 22px/22px "Poppins", sans-serif;
  color: #fff;
  background: -webkit-linear-gradient(272deg, #e0499b 10%, #f3dd59 65%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

.main_landing_page .tabbed_content h3
{
  font: 700 36px "Avenir-black", sans-serif;
  color: #fff;
  padding-bottom: 16px;
  
}
.main_landing_page .tabbed_content .tabbed_text ul
{
  padding-top: 20px;
  padding-bottom: 40px;
}
.main_landing_page .tabbed_content .bullet_list
{
  list-style-type: none;
}
.main_landing_page .tabbed_content .point
{
  background: url('../images/bullet-pointer.svg') no-repeat 0% 50%;
  font: 300 16px "Poppins", sans-serif;
  padding-left: 40px;
  margin-top: 20px;
  margin-left: -32px;
  color: #ffffffbb;
}
.main_landing_page .tabbed_content .point.two_line
{
  background: url('../images/bullet-pointer.svg') no-repeat 0% 23%;
}

.main_landing_page .tabbed_content .tabbed_img
{
  padding-top: 30px;
  padding-bottom: 20px;
  
}
.main_landing_page .tabbed_content .tabbed_img img
{
  position: relative;
  display: block;
  margin-top: auto !important;
  width: 90%;
  margin-left: 0px !important;
}
.main_landing_page .tabbed_content .tabbed_img_inverse
{
  
  padding-top: 30px;
  padding-bottom: 20px !important;
}
.main_landing_page .tabbed_content .tabbed_img_inverse img
{
  position: relative;
  display: block;
  margin-top: auto !important;
  margin-right: 0px !important;
  margin-left: auto;
  width: 90%;
}
@media screen and (max-width: 1200px)
{
  html,body
  {
    overflow-x: hidden;
  }
  .main_landing_page .tabbed_content .tabbed_img img
  {
    margin: auto !important;
    width: 70%;

  }
  .main_landing_page .tabbed_content .tabbed_img_inverse img
  {
    margin: auto !important;
    width: 70%;

  }
  .main_landing_page .tabbed_content .tabbed_img
  {
    padding-top: 0px; 
  }
  .main_landing_page .tabbed_content
  {
    padding: 10px 0px;
    padding-top: 20px;
  }
}
@media screen and (max-width: 767px)
{
  .main_landing_page .tabbed_content .tabbed_img img
  {
    margin: auto !important;
    width: 90%;

  }
  .main_landing_page .tabbed_content .tabbed_img_inverse img
  {
    margin: auto !important;
    width: 90%;

  }
  .main_landing_page .tabbed_content .tabbed_img
  {
    padding-top: 0px; 
  }
  .main_landing_page .tabbed_content
  {
    padding: 10px 0px;
    padding-top: 20px;
  }
  
  .main_landing_page .tabbed_content h5
  {
    font: 700 18px/20px "Poppins", sans-serif;
    
    background: -webkit-linear-gradient(272deg, #e0499b 10%, #f3dd59 65%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    
    margin-bottom: -10px;
  }
  .pricing_cards
  {
    padding: 1px;
  }
}
@media screen and (max-width: 1200px)
{
  .whyus_cards_section {
    position: relative;
    display: block;
    background: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20%;
  }
  .whyus_cards_section .whyus_right_side {
    width: 100%;
    float: auto;
    padding: 30px;
    
  }
  .whyus_cards_section .whyus_icon_card_right {
    width: 100%;
    max-width: 100%;
    
    height: auto;
    padding: 20px 50px;
    margin-left: 2%;
  }
  .whyus_cards_section .whyus_icon_card_right:nth-child(even)
  {
    margin-left: 2%;
    max-width: 95%;
  }

  .whyus_cards_section .whyus_left_side {
    width: 100%;
    padding: 30px;
    
  }
  .whyus_cards_section .whyus_icon_card_left {
    width: 100%;
    /* max-width: 400px; */
    max-width: 95%;
    padding: 20px 30px;
    height: auto;
  }
  .whyus_cards_section .whyus_icon_card_left:nth-child(odd)
  {
    margin-left: 0%;
  }
  .pricing_cards
  {
    height: auto;
    
  }
  .trusted_icons_section {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  .gradient_name {
    font: 700 60px "Avenir-black", sans-serif;
    letter-spacing: -4px;
  }
  .main_landing_page .first_section .text_section .p_para_1 {
    text-align: justify;
    width: 100%;
  }
  .main_landing_page .first_section .btn_section {
    padding-top: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .main_landing_page .first_section .btn_section div {
    display: block;
    margin: 0 auto !important;
    padding: 8px 0px !important;
  }
  .whyus_cards_section {
    position: relative;
    display: block;
  }
  .whyus_cards_section .whyus_left_side {
    width: 100%;
    
  }
  .whyus_cards_section .whyus_right_side {
    width: 100%;
  }

  .main_landing_page .expensive_time_taking .why_us_info_box {
    padding: 10px 20px;
    padding-top: 40px;
  }

  .first_section img
  {
    width: 80%;
  }
  .trusted_icons_section div {
    padding: 0px !important;
    width: 33%;
    padding-bottom: 20px !important;
  }
  .trusted_icons_section img
  {
    width: 90%;
    display: block;
    margin: 0 auto;
  }
  .main_landing_page .tabbed_content .tabbed_img img
  {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  .main_landing_page .tabbed_section
  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px;
  }
  .main_landing_page .tabbed_section .tabbed_item .tabbed_link
  {
    font-size: 0.8rem;
    width: 150px;
    text-align: center;
    margin: 0px !important;
    display: inline-block;
  }
  .main_landing_page .tabbed_section .tabbed_item .tabbed_link.active
  {
    font-size: 0.8rem;
  }
  .main_landing_page .tabbed_content h3
  {
    padding-top: 20px;
    font-size: 1.6rem;
  }
  .main_landing_page .tabbed_content .tabbed_text .pill_section
  {
    margin-bottom: 20px;
  }
  .main_landing_page .tabbed_content .tabbed_text .pill_section .each_pill
  {
    min-width: 46%;
    font-size: 0.9em;
    margin-right: 8px;
    margin-top: 8px;
  }
  .main_landing_page .project_cards_container .inner .project_text
  {
    font-size: 1rem;
  }
  .pricing_cards
  {
    height: auto;
    margin-bottom: 2rem;
    
  }
  .pricing_cards .pricing_cards_inner .blue_band h6
  {
    font-size: 1.4rem;
  }
  .pricing_heading_container
  {
    padding-bottom: 1rem;
  }
  .main_landing_page .community-box {
    margin-top: 0px !important;
    padding-bottom: 120px !important;
  }
}

.main_landing_page .backed_by_container
{
  padding: 60px 0px 60px;
  margin-bottom: 60px;
  background-color: #000;
}
.backed_by_container .backed_by_text {
  font: 700 40px "Avenir-black", sans-serif;
  
  color: #fff;
}
.backed_by_container .backed_by_image
{
  text-align: center;
}
.backed_by_container .backed_by_image a
{
  text-decoration: none;
}
.backed_by_container .backed_by_image img
{
  width: 160px;
}

@media screen and (max-width: 767px) {
  .main_landing_page .backed_by_container
  {
    padding: 32px 16px 24px;
    margin-bottom: 24px;
  }
  .backed_by_container .backed_by_text {
    font: 700 24px "Avenir-black", sans-serif;
    text-align: start;
  }
  .backed_by_container .backed_by_image
  {
    text-align: center;
  }
  .backed_by_container .backed_by_image img
  {
    width: 80px;
  }
}


/* team page styles */
.team_page
{
  padding: 120px 0px;
  min-height: 88.5vh;
  background: url('../images/second-webb.svg'),url('../images/background-pattern.svg');
  background-position: 0% 40%, 100% 80%;
  background-repeat: no-repeat,no-repeat;
  background-size: 30%,30%;
}
.team_gen .team_member_container
{
   width: 322px;
   padding-bottom: 50px;
}
.team_gen .team_member_container .img_container_team img
{
  display: block;
  margin: 0 auto;
}
.team_gen h4
{
  margin-top: 16px;
  text-align: center;
  font: 400 24px/32px "Poppins", sans-serif !important;
  color: #fff;
}
.team_gen h6
{
  margin-top: 8px;
  text-align: center;
  font: 400 18px/24px "Poppins", sans-serif !important;
  color: #fff;
}
.team_gen p
{
  text-align: center;
  font: 300 16px/22px "Poppins", sans-serif !important;
  color: #ffffffbb;
}
@media screen and (max-width: 767px) {
  .team_gen .team_member_container
  {
    width: 150px;
  }
  .team_gen .team_member_container .img_container_team img
  {
    display: block;
    margin: 0 auto;
    height: 150px;
  }
  /* .team_gen .team_member_container .img_container
  {
    height: 150px;
  } */
  .team_gen h4
  {
    font: 400 16px/20px "Poppins", sans-serif !important; 
  }
  .team_gen h6
  {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .team_gen p
  {
    font-size: 0.7rem !important;
    line-height: 1.1rem !important;
  }
  .team_vaishnavi
  {
    width: 39% !important;
  }

  
}
/* Experience SHYFT styles */
.experience_page
{
  padding: 120px 0px;
  min-height: 88.5vh;
  background: url('../images/second-webb.svg');
  background-position: 0% 40%;
  background-repeat: no-repeat;
  background-size: 40%;
}

/* About page Styles */
.about_styles
{
  padding: 120px 0px 80px;
  min-height: 88.5vh;
}

.dashboard
{
  padding: 120px 0px 40px;
  min-height: 85vh;
}
.dashboard .icon_title .icon_image img
{
  width: 50px;
}
.dashboard .icon_title .icon_text
{
  font: 400 24px/32px "Avenir-bold", sans-serif !important;
  padding-top: 8px;
  padding-left: 3px;
  color: #fff;
}
.dark_link_cards
{
  background-color: #000;
  font: 300 18px/20px "Poppins", sans-serif !important;
  color: #ffffffbb;
  margin-top: 10px;
  border-radius: 8px !important;
}

.dashboard .dark_link_list
{
  background-color: #000;
  padding: 8px;
  margin-top: 20px;
  border-radius: 8px;
}
.dashboard .dark_link_list .dark_link_list_item
{
  
  width: 98%;
  margin: 0 auto;
  padding: 10px 0px 12px;
  border-bottom: 1px solid #ffffff22;
  font: 300 18px/20px "Poppins", sans-serif !important;
  color: #ffffffbb;
}
.dashboard .dark_link_list .dark_link_list_item:last-of-type
{
  border-bottom: none;
}

/* Get API page Styles */
.get_api_page
{
  padding: 120px 0px 80px;
  min-height: 85vh;
}
.get_api_page .img_container
{
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.get_api_page .img_container img
{
  position: relative;
  margin: auto;
}
.get_api_page .the_form
{
  width: 80%;
}
.dark_form_group
{
  background: linear-gradient(90.8deg, #E0499B 3.34%, #5965F3 100%);
  padding: 2px;
  border-radius: 30px;
  
}
.dark_form_group div
{
  padding: 10px 16px;
  background-color: #111111;
  border-radius: 28px;
}
.dark_form_group div input
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
  font: 300 16px/18px "Poppins", sans-serif !important;
  
}
.dark_form_group div input:focus
{
  border: none !important;
  box-shadow: none;
}
.dark_form_group div select
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
  font: 300 16px/18px "Poppins", sans-serif !important;
  
  z-index: 2;
}
.dark_form_group div select:focus
{
  border: none !important;
  box-shadow: none;
}

.dark_form_field
{
  padding: 16px 0px;
  position: relative;
}
.dark_form_field.in_user_form
{
  padding: 8px 0px;
}

.dark_form_field label
{
  font: 300 16px/18px "Poppins", sans-serif !important;
  
  color: #ffffffdd !important;
  padding-bottom: 12px;
  padding-left: 2px;
}
.dark_form_field .down_icon
{
  position: absolute;
  background-color: transparent;
  color: #fff;
  right: 4px;
  bottom: 18px;
  z-index: 1;
}
.dark_form_field .card_input_clubbed
{
  background: linear-gradient(90.8deg, #E0499B 3.34%, #5965F3 100%);
  padding: 2px;
  border-radius: 8px;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner
{
  width: 100%;
  background-color: #111111;
  border-radius: 6px;
  padding: 0px 12px;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner input
{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
  color: #ffffffdd !important;
  font: 300 16px/18px "Poppins", sans-serif !important;
  
  padding: 16px 8px;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner input:hover
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner input:focus
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner input:active
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field select
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field select:hover
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field select:focus
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field select:active
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #111 inset !important;
    -webkit-text-fill-color: #fffd !important;
    border: none;
    color: #fffd;
}


@media screen and (max-width: 767px) {
  .get_api_page .the_form
  {
    width: 100%;
    padding: 10px;
  }
}

.full_overlay
{
  position: fixed;
  height: 120vh;
  width: 100vw;
  z-index: 99;
  background-color: #21212122;
  overflow: hidden !important;
  margin-top: -150px;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.full_overlay .loading_square
{
  width: 90%;
  max-width: 600px;
  background: #212121;
  border-radius: 20px;
  position: relative;
  padding: 32px !important;
}
.full_overlay .loading_square .close_buttons
{
  position: absolute;
  right: 10px;
  top: 10px;
}
.full_overlay .loading_square .close_buttons button
{
  background-color: transparent;
  border: none;
}
.full_overlay .loading_square h3
{
  font: 400 24px/32px "Poppins", sans-serif !important;
  color: #fff;
  
  padding-bottom: 16px;
  padding-top: 12px;
}
.btn_small_ok
{
  background: linear-gradient(90.8deg, #E0499B 3.34%, #5965F3 100%);
  border-radius: 20px;
  padding: 8px 24px;
  font: 300 14px/18px "Poppins", sans-serif !important;
  color: #fff;
  
  border: none;
  display: block;
}
.full_overlay .loading_square h4
{
  font: 300 16px/18px "Poppins", sans-serif !important;
  color: #fff;
  
}
.loader
{
  width: 120px;
  height: 120px;
  border-radius: 16px;
  background: #000;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.api_key_container
{
  background-color: #000;
  padding: 12px 20px;
  border-radius: 8px;
}
.api_key_container .the_api_key
{
  background-color: transparent;
  border: none;
  padding-top: 8px;
  font: 300 16px/18px "Poppins", sans-serif !important;
  color: #ffffff;
  
}


/* Calendly Button */
.book_demo {
  display: inline-block;
  position: fixed;
  z-index: 99;
  bottom: 20px;
  right: 20px;
  /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 2.5rem;
  background-color: rgba(89, 101, 243, 0.29);
  background: url('../images/call-phone.svg'),linear-gradient(180deg, #FF512F 0%, #f3dd59 100%);
  background-position: center,center;
  background-repeat: no-repeat,no-repeat;
  background-size: 40%,cover;
  color: #fff;
  font: 600 0.8rem/1.2rem "Poppins", sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.2s;
}
.book_btn_1
{
  margin-top: 5px;
}
/* Terms page Styles */
.terms_main_title {
  font: 700 60px/88px "Montserrat", sans-serif;
  
  color: #fff;
}
.terms_sub_h {
  color: #fff;
  font: 500 1.2rem/1.8125rem "Poppins", sans-serif;
  
  margin-top: 2rem;
}
.terms_para {
  color: #fffd;
  font: 300 1rem/1.8125rem "Poppins", sans-serif;
  margin-top: 1rem;
  
}
.terms_container
{
  padding: 120px 0px 40px;
}
@media screen and (max-width: 600px) {
  .book_demo
  {
      width: 100%;
      background: #000000bb;
      backdrop-filter: blur(6px);
      color: #fff;
      border-radius: 0px;
      font-weight: 300;
      padding: 16px 0px;
      bottom: 0;
      right: 0;
  }
  .book_demo::before
  {
      content: 'Book A Demo';
  }
  .full_overlay .loading_square h3
  {
    font: 400 18px/26px "Poppins", sans-serif !important;
    color: #fff;
    
    padding-bottom: 16px;
    padding-top: 12px;
  }
  .full_overlay
  {
    position: fixed;
    height: 130vh;
    
  }
  .terms_container
  {
    padding: 60px 0px 40px;
  }
}

/* Marketplace Page Styles  */

.marketplace_landing
{
  background: url('../images/background-pattern.svg'),url('../images/second-webb.svg'),url('../images/background-pattern.svg');
  background-repeat: no-repeat,no-repeat,no-repeat;
  background-position: 100% 80%,0% 35%,100% 8%;
  background-size: 40%,40%,40%;
}
/* Marketplace Page Styles  */

.marketplace_landing .hero_section {
  padding-top: 120px !important;
}
.marketplace_landing .hero_section .main_headings {
  padding-top: 40px;
}
.marketplace_landing .hero_section .p_para_1 {
  padding-top: 30px;
}
.marketplace_landing .hero_section .img_container img {
  display: block;
  margin-left: auto;
  width: 70%;
}

.marketplace_landing .hero_section .btn_container {
  padding-top: 48px;
}
@media screen and (max-width: 767px) {
  .marketplace_landing
  {
    padding: 0px 12px;
  }
  .marketplace_landing .hero_section .main_headings
    {
        padding-top: 30px;
    }
    .marketplace_landing .hero_section .p_para_1
    {
        padding-top: 20px;
    }
    .marketplace_landing .hero_section .btn_container
    {
        padding-top: 20px;
    }
    .marketplace_landing .hero_section .img_container img
    {
        margin: 0 auto;
        margin-top: 60px;
        width: 70%;
        max-width: 300px;
    }

    .marketplace_landing .section_div
    {
      padding: 40px 0px !important;
    }
}


.marketplace_landing .with_without_section .main_headings {
  padding-bottom: 40px;
}

.marketplace_landing .dark_wwo_container {
  background-color: #1a1919;
  min-height: 200px;
  height: 100%;
  border-radius: 20px;
  padding: 10px 20px;
  padding-bottom: 24px;
}
.marketplace_landing .single_point {
  display: flex;
  margin-bottom: 10px;
}
.marketplace_landing .single_point .bullet_container {
  width: 5%;
}
.marketplace_landing .bolder_points .bullet_container {
  width: 10% !important;
}

.marketplace_landing .single_point .text_container {
  font: 300 16px/24px "Poppins", sans-serif;
  color: #ffffffdd;
  width: 95%;
  padding-top: 2px;
  letter-spacing: 2px;
}
.marketplace_landing .bolder_points .text_container {
  font: 600 16px/24px "Poppins", sans-serif !important;
  width: 90% !important;
}
.marketplace_landing .red_title {
  text-align: center;
  color: #fff;
  font: 700 40px/48px "Avenir-black", sans-serif;
  
  margin-top: 20px;
  margin-bottom: 20px;
}
.marketplace_landing .red_title span {
  background: linear-gradient(180deg, #e0499b 0%, #f3dd59 100%);
  font: 700 40px/48px "Avenir-black", sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.marketplace_landing .green_title {
  text-align: center;
  color: #fff;
  font: 700 40px/48px "Avenir-black", sans-serif;
  
  margin-top: 20px;
  margin-bottom: 20px;
}
.marketplace_landing .green_title span {
  background: linear-gradient(180deg, #4E58CD 0%, #97D2FF 100%);
  font: 700 40px/48px "Avenir-black", sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.marketplace_landing .border_section {
  background: linear-gradient(
    102.4deg,
    #0aeeff -1.46%,
    #e0499b -1.45%,
    #5965f3 98.76%
  );
  padding: 2px;
  border-radius: 20px;
  height: 100%;
}
@media screen and (max-width: 600px)
{
  .marketplace_landing .single_point .bullet_container
  {
      width: 10%;
  }
  .marketplace_landing .single_point .text_container
  {
      width: 90%;
      
  }
}

/* .whyus_section .main_headings {
  padding-top: 20px !important;
} */

.whyus_section .p_para_1 {
  padding-top: 10px;
}

.why_us_info_box {
  padding: 64px 0px;
}

.why_box_outer {
  background: linear-gradient(
    102.4deg,
    #0aeeff -1.46%,
    #e0499b -1.45%,
    #5965f3 98.76%
  );
  width: 100%;
  /* max-width: 300px; */
  min-height: 130px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 1px;
}

.why_box_outer .why_box_inner {
  position: relative;
  width: 100%;
  min-height: 170px;
  border-radius: 15px;
  background-color: #111;
  color: #fff;
  padding: 32px 25px 10px;
}

.why_box_outer .why_box_inner h3 {
  font: 700 24px "Avenir-bold", sans-serif;
}

.why_box_outer .why_box_inner .tag_img {
  position: absolute;
  z-index: 3;
  top: -20px;
  left: -24px;
}

.why_box_outer .why_box_inner .tag_img img {
  width: 54px;
}

.marketplace_landing .trusted_icons_section {
  padding-bottom: 0px;
}
@media screen and (max-width: 767px)
{
  .whyus_section .p_para_1 {
    padding-top: 8px;
  }
  .why_us_info_box {
    padding: 32px 0px;
  }
  .why_box_outer
  {
    min-height: 120px;
  } 
  .why_box_outer .why_box_inner
  {
    min-height: 122px;
    padding: 24px 25px 10px;
  }
}

/* nft landing page styles */
.nft_landing
{
  padding: 120px 0px 40px;
  background: url('../images/nfts_page/nft-cover-background.png'),url('../images/background-pattern.svg'),url('../images/background-pattern.svg'),url('../images/second-webb.svg'),url('../images/second-webb.svg');
  background-position: 82% 0%,100% 58%,100% 104%,0% 22%,0% 88%;
  background-repeat: no-repeat,no-repeat,no-repeat,no-repeat,no-repeat;
  background-size: 24%,40%,40%,40%,40%;
}
@media screen and (max-width: 1400px) {
  .nft_landing
  {
    padding-top: 80px;
    background-size: 30%;
  }
}
@media screen and (max-width: 1200px) {
  .nft_landing
  {
    padding-top: 40px;
    background-size: 32%;
    background-position: 90% 0%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .nft_landing
  {
    padding-top: 40px;
    background: url('../images/nfts_page/nft-cover-image.png');
    background-position: 95% 2%;
    background-repeat: no-repeat;
    background-size: 30%;
  }
}
.nft_landing .hero_section .main_headings {
  padding-top: 40px;
}
.nft_landing .hero_section .p_para_1 {
  padding-top: 30px;
}
.nft_landing .hero_section .img_container img {
  display: block;
  margin-left: auto;
  width: 70%;
}

.nft_landing .hero_section .btn_container {
  padding-top: 48px;
}
.nft_landing .section_div
{
  padding: 60px 0px !important;
}
@media screen and (max-width: 767px) {
  .nft_landing
  {
    padding: 0px 12px;
    background: url('../images/background-pattern.svg'),url('../images/background-pattern.svg'),url('../images/second-webb.svg'),url('../images/second-webb.svg');
    background-position: 100% 58%,100% 104%,0% 22%,0% 88%;
    background-repeat: no-repeat,no-repeat,no-repeat,no-repeat;
    background-size: 40%,40%,40%,40%;
  }
  .nft_landing .hero_section .main_headings
    {
        padding-top: 30px;
    }
    .nft_landing .hero_section .p_para_1
    {
        padding-top: 20px;
    }
    .nft_landing .hero_section .btn_container
    {
        padding-top: 20px;
    }
    .nft_landing .hero_section .img_container img
    {
        margin: 0 auto;
        margin-top: 60px;
        width: 50%;
        max-width: 300px;
    }

    .nft_landing .section_div
    {
      padding: 40px 0px !important;
    }
}

.nft_landing .with_without_section .main_headings {
  padding-bottom: 40px;
}

.nft_landing .dark_wwo_container {
  background-color: #1a1919;
  min-height: 200px;
  height: 100%;
  border-radius: 20px;
  padding: 10px 20px;
  padding-bottom: 24px;
}
.nft_landing .single_point {
  display: flex;
  margin-bottom: 10px;
}
.nft_landing .single_point .bullet_container {
  width: 5%;
}
.nft_landing .bolder_points .bullet_container {
  width: 10% !important;
}

.nft_landing .single_point .text_container {
  font: 300 16px/24px "Poppins", sans-serif;
  color: #fff;
  width: 95%;
  padding-top: 2px;
  letter-spacing: 2px;
}
.nft_landing .bolder_points .text_container {
  font: 600 16px/24px "Poppins", sans-serif !important;
  width: 90% !important;
}
.nft_landing .red_title {
  text-align: center;
  color: #fff;
  font: 700 40px/48px "Avenir-black", sans-serif;
  
  margin-top: 20px;
  margin-bottom: 20px;
}
.nft_landing .red_title span {
  background: linear-gradient(180deg, #e0499b 0%, #f3dd59 100%);
  font: 700 40px/48px "Avenir-black", sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.nft_landing .green_title {
  text-align: center;
  color: #fff;
  font: 700 40px/48px "Avenir-black", sans-serif;
  
  margin-top: 20px;
  margin-bottom: 20px;
}
.nft_landing .green_title span {
  background: linear-gradient(180deg, #4E58CD 0%, #97D2FF 100%);
  font: 700 40px/48px "Avenir-black", sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.nft_landing .border_section {
  background: linear-gradient(
    102.4deg,
    #0aeeff -1.46%,
    #e0499b -1.45%,
    #5965f3 98.76%
  );
  padding: 2px;
  border-radius: 20px;
  height: 100%;
}
@media screen and (max-width: 600px)
{
  .nft_landing .single_point .bullet_container
  {
      width: 10%;
  }
  .nft_landing .single_point .text_container
  {
      width: 90%;
      
  }
}


.nft_landing .tabbed_section
{
  background-color: #222;
  border-radius: 32px;
  padding: 8px 0px;
}
.nft_landing .tabbed_section .tabbed_item .tabbed_link
{
  
  font: 400 18px "Poppins", sans-serif;
  color: #fff;
  transition: all 0.2s ease;
}
.nft_landing .tabbed_section .tabbed_item .tabbed_link.active
{
  
  font: 700 18px "Poppins", sans-serif;
  background: -webkit-linear-gradient(-30deg, #E0499B 20%, #F3DD59 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.nft_landing .tabbed_content
{
  padding: 10px 0px 20px;
  /* padding-top: 10px; */
}
.nft_landing .tabbed_content .tabbed_text
{
  padding: 30px 0px;
}
.nft_landing .tabbed_content .tabbed_text .pill_section 
{
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 60px;
}
.nft_landing .tabbed_content .tabbed_text .pill_section .each_pill
{
  padding: 8px 16px;
  border-radius: 24px;
  background-color: #222;
  font: 400 14px "Poppins", sans-serif;
  
  color: #ffffffbb;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 9px;
  min-width: 160px;
  text-align: center;
}

.nft_landing .tabbed_content .tabbed_text .pill_section button.each_pill
{
  padding: 8px 16px;
  border-radius: 24px;
  background-color: #222;
  font: 400 14px "Poppins", sans-serif;
  
  color: #ffffffbb;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 9px;
  min-width: 160px;
  text-align: center;
  border: none !important;
}

.nft_landing .tabbed_content h5
{
  font: 700 22px/26px "Poppins", sans-serif;
  color: #fff;
  background: -webkit-linear-gradient(272deg, #e0499b 10%, #f3dd59 65%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

.nft_landing .tabbed_content h5.large_text
{
  font: 700 22px/26px "Poppins", sans-serif;
  color: #fff;
  background: -webkit-linear-gradient(272deg, #e0499b 10%, #f3dd59 65%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

.nft_landing .tabbed_content h3
{
  font: 700 36px "Avenir-black", sans-serif;
  color: #fff;
  padding-bottom: 16px;
  
}
.nft_landing .tabbed_content .tabbed_text ul
{
  padding-top: 20px;
  padding-bottom: 40px;
}
.nft_landing .tabbed_content .bullet_list
{
  list-style-type: none;
}
.nft_landing .tabbed_content .point
{
  background: url('../images/bullet-pointer.svg') no-repeat 0% 50%;
  font: 300 16px "Poppins", sans-serif;
  padding-left: 40px;
  margin-top: 20px;
  margin-left: -32px;
  color: #ffffffbb;
}
.nft_landing .tabbed_content .point.two_line
{
  background: url('../images/bullet-pointer.svg') no-repeat 0% 23%;
}

.nft_landing .tabbed_content .tabbed_img
{
  padding-top: 30px;
  padding-bottom: 20px;
  
}
.nft_landing .tabbed_content .tabbed_img img
{
  position: relative;
  display: block;
  margin-top: auto !important;
  width: 90%;
  margin-left: 0px !important;
}
.nft_landing .tabbed_content .tabbed_img_inverse
{
  
  padding-top: 30px;
  padding-bottom: 20px !important;
}
.nft_landing .tabbed_content .tabbed_img_inverse img
{
  position: relative;
  display: block;
  margin-top: auto !important;
  margin-right: 0px !important;
  margin-left: auto;
  width: 90%;
}
@media screen and (max-width: 767px) {
  .nft_landing .tabbed_content .tabbed_img img
  {
    margin: auto !important;
    width: 70%;

  }
  .nft_landing .tabbed_content .tabbed_img_inverse img
  {
    margin: auto !important;
    width: 70%;

  }
  .nft_landing .tabbed_content .tabbed_img
  {
    padding-top: 0px; 
  }
  .nft_landing .tabbed_content
  {
    padding: 10px 0px;
    padding-top: 20px;
  }
  .nft_landing .tabbed_content .tabbed_text .pill_section 
  {
    margin: 0 auto;
    margin-bottom: 30px;
    justify-content: flex-start;
  }
  .nft_landing .tabbed_content .tabbed_text .pill_section .each_pill
  {
    min-width: 140px;
  }
  .nft_landing .tabbed_content .tabbed_text .pill_section .each_pill
  {
    min-width: 140px;
  }
  .nft_landing .tabbed_content .tabbed_text:last-of-type
  {
    padding-bottom: 0px;
  }
}

.changer
{
  opacity: 1.0;
  transition: display 5s ease;
}
.postman_grad {
  background-color: #DD499D;
  background: linear-gradient(90.8deg, #DD499D 3.34%, #5D64F0 100%) !important;
  height: 100% !important;
  width: auto !important;
  padding-top: 7px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 25px !important;
  transition: all 0.3s ease;
}
.postman_grad::before {
  content: "Run in Postman";
  font: 600 0.8rem/1rem "Poppins", sans-serif;
  
  color: #fff;
}
.postman_grad:hover {
  transform: scale(1.05);
}
.btn_activate
{
  background: -webkit-linear-gradient(270deg, #e0499b 2%, #f3dd59 98%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}
.activate_outer
{
  padding: 1px !important;
  background-color: #DD499D;
  background: linear-gradient(90.8deg, #DD499D 3.34%, #5D64F0 100%) !important;
  
}
.activate_outer div
{
  background-color: #222;
  width: 100% !important;
  height: 100% !important;
  padding-top: 7px;
  border-radius: 24px;
  text-align: center;
}

/*New Support Pages Added */

.support_page
{
  padding: 160px 0px;
  min-height: 88.5vh;
  background: url('../images/second-webb.svg'),url('../images/background-pattern.svg');
  background-position: 0% 40%, 100% 80%;
  background-repeat: no-repeat,no-repeat;
  background-size: 30%,30%;
}

@media screen and (max-width: 767px)
{
  .support_page
  {
    padding: 80px 0px 40px;
  } 
}

/* Login page Styles */
.login_page
{
  min-height: 60.5vh;
}
.login_page .img_container img
{
  width: 101%;
  object-fit: cover;
}
.form_boxx
{
  padding: 160px 0px 80px;
}
.login_heading {
  font: 700 40px "Poppins", sans-serif;
  letter-spacing: 2px;
  color: #fff;
  padding-bottom: 20px;
}
.w_65
{
  width: 65% !important;
}
.google_full_width {
  display: inline-block;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  width: 100%;
  border: none;
  border-radius: 2.5rem;
  background-color: #fff;
  background: linear-gradient(
    160.8deg,
    #FFFFFF 3.34%,
    #6590FF 100%
  );
  color: #112f4a;
  font: 700 1.1rem/1.2rem "Poppins", sans-serif;
  
  text-align: center;
  text-decoration: none !important;
  transition: all 0.2s;
}
.google_full_width img
{
  width: 30px;
  margin-right: 16px;
}
.btn_solid_grad_full_width:hover {
  transform: scale(1.02);
  color: #fff;
}
@media screen and (max-width: 767px)
{
  .login_page .img_container img
  {
    display: none;
  }
  .w_65
  {
    width: 95% !important;
  } 
}

/* Dashboard Page Styles */
.dashboard_main_title
{
  font: 700 22px/28px "Avenir-black", sans-serif;
  color: #ffffffcc;
  
}
.dashboard_main_pop_title
{
  font: 700 22px/28px "Poppins", sans-serif;
  color: #ffffffcc;
  
}
.dashboard_sub_title
{
  font: 300 16px/26px "Poppins", sans-serif;
  color: #ffffffcc;
  
}
@media screen and (min-width: 1331px) and (max-width: 1600px) {
  .dashboard_main_title
  {
    font: 700 20px/26px "Avenir-black", sans-serif;
  }
  .dashboard_sub_title
  {
    font: 300 14px/26px "Poppins", sans-serif;
  }
}
@media screen and (min-width: 992px) and (max-width: 1330px) {
  .dashboard_main_title
  {
    font: 400 18px/24px "Avenir-black", sans-serif;
    color: #ffffffcc;
    
  }
  .dashboard_sub_title
  {
    font: 300 12px/22px "Poppins", sans-serif;
  }
}
@media screen and (max-width: 767px) {
  .dashboard_main_title
  {
    font: 700 20px/26px "Avenir-black", sans-serif;
  }
  .dashboard_sub_title
  {
    font: 300 14px/26px "Poppins", sans-serif;
  }
}

.support_box {
  margin-top: 10px;
  padding: 20px 20px;
  background-color: #000;
  border-radius: 15px;
  min-height: 200px

}
.support_box .icon_boxx {
  margin-bottom: 8px;
}

.support_box .p_para_1 {
  padding-top: 0px;
  font-size: 14px;
}

.support_box .icon_boxx .data {
  font: 400 24px "Poppins", sans-serif;
  padding-top: 4px;
  padding-left: 0px;
  color: #fff;
  
}

.support_box .icon_boxx {
  margin-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .support_box
  {
    margin-top: 2px;
    padding: 16px;
  }
  .support_box .icon_boxx {
    margin-bottom: 2px;
  }
  .support_box .p_para_1 {
    padding-top: 0px;
    font-size: 12px;
  }
}

.billings
{
  padding: 120px 0px;
}
.billings .p_para_bolder {
  font: 500 16px "Poppins", sans-serif;
  
  color: #ffffff;
}

.billings .overview_card
{
  background-color: #000;
  border-radius: 16px;
  margin-top: 20px;
}
.billings .overview_card .payable_details
{
  padding: 40px 16px;
}
.billings .overview_card h4
{
  font: 400 18px "Poppins", sans-serif;
  color: #ffffffdd;
  text-align: center;
}
.billings .overview_card .price
{
  font: 600 40px/48px "Poppins", sans-serif;
  background: -webkit-linear-gradient(280deg, #e0499b 30%, #f3dd59 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 16px;
}
.billings .overview_card .bill_details
{
  border-left: 2px solid #111111;
  padding: 32px 24px;
}
.billings .overview_card .bill_details .p_para_1
{
  font-size: 16px;
}

.billings .billing_table
{
  margin-top: 16px;
}
.billings .billing_table .table_head
{
  padding: 12px 20px 8px;
  background-color: #000;
  border-radius: 12px;
}
.billings .billing_table .table_body
{
  padding: 12px 20px 8px;
  border-radius: 12px;
}
.billings .billing_table .table_head h5
{
  font: 400 16px "Poppins", sans-serif;
  color: #fff;
}
.billings .billing_table .table_body h5
{
  font: 300 16px "Poppins", sans-serif;
  color: #fff;
}

@media screen and (max-width: 767px) 
{
  .billings
  {
    padding: 88px 0px;
  }
  .billings .overview_card .payable_details
  {
    padding: 24px 16px 0px;
  }
  .billings .billing_table .table_head h5
  {
    font-size: 14px;
  }
  .billings .billing_table .table_body h5
  {
    font-size: 12px;
  }
}

/* Resource Page Styles */
 .resource
{
  padding: 120px 0px 80px;
}

.cyan_link_list
{
  background-color: #000;
  padding: 8px;
  margin-top: 20px;
  border-radius: 8px;
}
.cyan_link_list .cyan_link_list_item
{
  
  width: 98%;
  margin: 0 auto;
  padding: 16px 0px 16px;
  border-bottom: 1px solid #ffffff22;
  font: 300 16px/18px "Poppins", sans-serif !important;
  color: #ffffff;
}
.cyan_link_list .cyan_link_list_item.is_last
{
  border-bottom: none;
}

/* Overview Styles */
.overview
{
  padding: 120px 0px 80px;
}
.overview .overview_dark
{
  margin-top: 2px;
  background-color: #010101;
  padding: 18px 20px 12px;
  border-radius: 8px;
  min-height: 78px;
}
.overview .overview_dark h4
{
  font: 600 20px/22px "Poppins", sans-serif;
  color: #ddd;
  
  padding-top: 7px;
}
@media screen and (min-width: 1331px) and (max-width: 1600px) {
  .overview .overview_dark
  {
    margin-top: 2px;
    background-color: #010101;
    padding: 16px 20px 12px;
    border-radius: 8px;
    min-height: 50px;
  }
  .overview .overview_dark h4
  {
    font: 600 16px/20px "Poppins", sans-serif;
    color: #fff;
    
    padding-top: 7px;
  }
}
.overview .overview_dark .copy_btn
{
  background-color: transparent;
  border: none;
  padding: 6px;
  margin-top:-2px;
}

.overview .credit_section_outer
{
  padding: 1px !important;
  background-color: #DD499D;
  background: linear-gradient(175deg, #DD499D 3.34%, #5D64F0 100%) !important;
  border-radius: 8px;
  margin-top: 16px;
}
.overview .credit_section_outer .credit_section_inner
{
  background-color: #000;
  padding: 36px 20px;
  border-radius: 8px;
}
.overview .credit_section_outer .credit_section_inner h5
{
  font: 700 36px/42px "Poppins", sans-serif;
  background: -webkit-linear-gradient(280deg, #e0499b 30%, #f3dd59 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}
.overview .credit_section_outer .credit_section_inner h6
{
  font: 600 20px/28px "Poppins", sans-serif;
  color: #ffffffaa;
  
}
@media screen and (max-width: 767px) {
  .overview
  {
    padding: 88px 0px;
  }
  .overview .overview_dark
  {
    margin-top: 8px;
    padding: 10px 6px;
    min-height: 0px;
  }
  .overview .overview_dark h4
  {
    font-size: 14px;
  }
  .overview .overview_dark .copy_btn
  {
    padding: 6px;
    margin-left: -16px;
  }
  .overview .credit_section_outer .credit_section_inner
  {
    padding: 24px 20px 16px;
  }
  .overview .credit_section_outer .credit_section_inner h5
  {
    margin-bottom: 2px;
  }
  .overview .credit_section_outer .credit_section_inner h6
  {
    font-size: 1rem;
    margin-bottom: 16px;
  }
}


/* API Usage Styles */

.api_usage
{
  padding: 120px 0px 80px;
}
.api_usage div select
{
  background-color: black !important;
  background-image:url('../images/down-chevron.svg');
  background-size: 20px 20px;
  border: transparent;
  border-radius: 10px;
  color: #ffffffdd !important;
  font: 300 16px/18px "Poppins", sans-serif !important;
  z-index: 2;
  padding: 10px 20px;
}
.api_usage div select:focus
{
  border: none !important;
  box-shadow: none;
}
.api_usage .dark_area
{
  margin-top: 10px;
  background-color: #000;
  padding: 24px 20px;
  border-radius: 8px;
  font: 500 18px/20px "Poppins", sans-serif;
  color: #fff;
}

.api_usage .dark_table
{
  margin-top: 30px;
}
.api_usage .dark_table .dark_table_head
{
  background-color: #000;
  padding: 16px 20px;
  font: 300 16px/18px "Poppins", sans-serif;
  color: #fff;
  border-radius: 8px;
}
.api_usage .dark_table .dark_table_record
{
  padding: 16px 20px;
  font: 300 16px/18px "Poppins", sans-serif;
  color: #fff;
  word-wrap: break-word;
  border-radius: 8px;
  margin-top: 8px;
}
.api_usage .chart_background_outer
{
  background-color: #000;
  border-radius: 8px;
  padding: 32px 32px 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.api_usage .chart_background
{
  width: 100%;
  max-width: 1100px;
}
.chart_legend_container
{
  display: flex;
  align-items: center;
  justify-content: end;
  width: 170px;
  float: right;
  margin-bottom: 6px;
}
.chart_legend_container .icon
{
  width: 16px;
  height: 16px;
  border-radius: 15px;
  border: 1px solid white;
  margin-right: 4px;

}
.chart_legend_container .icon.pink
{
  background-color: #E0499B;
}
.chart_legend_container .icon.purple
{
  background-color: #5965F3;
}
.chart_legend_container .icon.orange
{
  background-color: #ff6c37ee;
}
.chart_legend_container .legend_text
{
  font: 300 0.8rem/1.1rem "Poppins", sans-serif;
  color: #fff;
  
}
.api_usage .month_indicator
{
  font: 300 0.8rem/1.1rem "Poppins", sans-serif;
  color: #fff;
  letter-spacing: 0px;
  margin-bottom: 16px;
}
.api_usage .selector_dropdowns
{
  
}
@media screen and (max-width: 767px) {
  .api_usage
  {
    padding: 88px 0px;
  }
  .api_usage .dark_area
  {
    padding: 18px 16px;
    font-size: 14px;
  }
  .api_usage .dark_table
  {
    margin-top: 16px;
  }
  .api_usage .dark_table .dark_table_head
  {
    padding: 14px 4px;
    font-size: 14px;
    line-height: 16px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .api_usage .dark_table .dark_table_record
  {
    padding: 14px 4px;
    font-size: 12px;
    line-height: 14px;
    margin-left: 2px;
    margin-right: 2px;
  }
  /* .api_usage div select
  {
    background-image:url('../images/down-chevron.svg');
    background-size: 1%;
  } */
  .api_usage .chart_background_outer
  {
    padding: 16px 16px 24px;
  }
  .chart_legend_container .legend_text
  {
    font: 300 0.7rem/0.9rem "Poppins", sans-serif;
  }
  .api_usage .month_indicator
  {
    font: 300 0.7rem/0.9rem "Poppins", sans-serif;
  }
  .chart_legend_container .icon
  {
    width: 12px;
    height: 12px;
  }
}

/* The pricing page and the cost estimator */
.pricing_page
{
  padding: 120px 0px 80px;
}
.pricing_page .plan_table
{
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
  display: block;
}
.pricing_page .plan_table .plan_table_head
{
  padding: 24px 20px 16px 16px;
  background-color: #242424;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  
}
.pricing_page .plan_table .plan_table_head h3
{
  font: 600 20px/24px "Poppins", sans-serif !important;
  color: #fff;
}
.pricing_page .plan_table .plan_table_body
{
  background-color: #000;
  padding: 20px 16px 16px;
  
}
.pricing_page .plan_table .plan_table_body:last-of-type
{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-bottom: 30px;
}
.pricing_page .plan_table .plan_table_body h3
{
  font: 300 18px/20px "Poppins", sans-serif !important;
  color: #fff;
}
.pricing_page .plan_table .plan_table_body h3.highlight
{
  font: 600 18px/20px "Poppins", sans-serif !important;
  background: -webkit-linear-gradient(280deg, #e0499b 30%, #f3dd59 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pricing_page .plan_table_mobile
{
  display: none;
}
.pricing_page .plan_table_mobile .plan_mobile_head
{
  padding: 16px 20px;
  text-align: center;
  font: 500 20px/24px "Poppins", sans-serif !important;
  color: #fff;
  background-color: #242424;
  letter-spacing: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.pricing_page .plan_table_mobile .plan_mobile_body
{
  padding: 16px 20px 12px;
  text-align: center;
  background-color: #000;
  font: 300 14px/18px "Poppins", sans-serif !important;
  color: #fff;
}
.pricing_page .plan_table_mobile .plan_mobile_body .highlight
{
  font-weight: 500;
  background: -webkit-linear-gradient(280deg, #e0499b 20%, #f3dd59 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricing_page .plan_table_mobile .plan_mobile_body:last-of-type
{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-bottom: 30px;
}

.pricing_page .pricing_text
{
  font: 600 20px/28px "Poppins", sans-serif;
  color: #fffd;
  margin-top: 40px;
  letter-spacing: 0px;
}
.pricing_page .note_text
{
  /* border: 1px solid #CD4E4E; */
  border-radius: 8px;
  margin-top: 24px;
  padding: 24px;
  color: #fffd;
  background-color: #000;
  font: 300 16px/24px "Poppins", sans-serif;
  
}
.pricing_page .note_text b
{
  font-weight: 600;
  letter-spacing: 0px;
}
@media screen and (max-width: 992px)
{
  .pricing_page .plan_table_mobile
  {
    display: block;
  }
  .pricing_page .plan_table
  {
    display: none;
  }
}
@media screen and (max-width: 767px)
{
  .pricing_page
  {
    padding: 88px 0px;
  }
  
  .pricing_page .note_text
  {
    font: 300 0.9em "Poppins", sans-serif;
    margin-top: 16px;
    padding: 16px;
  }
  .pricing_page .pricing_text
  {
    margin-top: 24px;
    margin-bottom: 16px;
    font: 600 0.9em "Poppins", sans-serif;
  }
}

#rangeValue {
  position: relative;
  display: block;
  text-align: center;
  font-size: 6em;
  color: #999;
  font-weight: 400;
}
.range {
  width: 100%;
  height: 15px;
  -webkit-appearance: none;
  /* background: linear-gradient(108.9deg, #FFFFFF 1.54%, #6590FF 107.38%); */
  background-color: #ffffffdd;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  border: 3px solid #000;
  box-shadow: -2807px 0 0 2800px #00d4ff;
}

.cost_estimator
{
  background-color: #000;
  padding: 0px 24px;
  border-radius: 8px;
  margin-top: 40px;
}

.marker_text
{
  margin-top: 2px;
  font: 300 18px/30px "Poppins", sans-serif !important;
  color: #fff;
  padding-bottom: 20px;
}
.marker_text div
{
  width: 22px;
}
.marker_anime
{
  position: relative;
  opacity: 0.0;
  display: none;
  transition: all 0.3s ease;
}
@-webkit-keyframes grow {
  0% { transform: scale(1); }
  50% { transform: scale(2); }
  100% { transform: scale(1); }
}
@keyframes grow {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}
.marker_anime .grow_cont
{
  position:absolute;
  top : -60px;
  
  left:-44px;
  height:30px;
  width:30px;
  border-radius: 25px;
  /* -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform background-color;
  -moz-transition-duration: 1s;
  -webkit-animation-name: grow;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: grow;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear; */
}
.marker_anime .grow_cont img
{
  width: 140px;
}

.cost_per_credit p
{
  font: 400 16px/18px "Poppins", sans-serif !important;
  color: #fffd;
  text-align: center;
  margin-top: 16px;
}

.cost_per_credit h3
{
  font: 700 52px/56px "Poppins", sans-serif;
  background: -webkit-linear-gradient(268deg, #e0499b 29%, #f3dd59 85%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  
}
.estimated_border
{
  border-left: 1px solid #242424;
}
.estimated_cost:first-of-type
{
  margin-top: 0px;
}
.estimated_cost:last-of-type
{
  margin-top: 40px;
  margin-bottom: 16px;
}
.estimated_cost p
{
  font: 400 16px/18px "Poppins", sans-serif !important;
  color: #fffd;
  text-align: center;
  letter-spacing: 0px;
  margin-top: 10px;
}


.estimated_cost h3
{
  font: 700 40px/44px "Poppins", sans-serif;
  background: -webkit-linear-gradient(268deg, #e0499b 29%, #f3dd59 85%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  word-wrap: break-word;
  margin-top: -10px;
  
}
@media screen and (max-width: 1200px)
{
  .estimated_border
  {
    border-left: none;
  }
  .estimated_cost:first-of-type
  {
    margin-top: 16px;
  }
  .estimated_cost:last-of-type
  {
    margin-top: 49px;
    
  }
  .estimated_cost p
  {
    font: 400 14px/16px "Poppins", sans-serif !important;
  }
}
@media screen and (max-width: 767px)
{
  .estimated_cost p
  {
    margin-top: 2px;
  }
  .estimated_cost h3
  {
    margin-top: -8px;
  }
  .estimated_cost:first-of-type
  {
    margin-top: 0px;
  }
  .estimated_cost:last-of-type
  {
    margin-bottom: 16px;
  }
  .marker_text
  {
    font: 300 12px/20px "Poppins", sans-serif !important;
    color: #fff;
    padding-bottom: 8px;
  }

  .estimated_border
  {
    border-left: none;
  }
}



/* .blurred_back
{
  height: 100% !important;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  backdrop-filter: blur(4px);
}
.blurred_back .main_div
{
  min-height: 200px;
  width: 100%;
  max-width: 500px;
  border-radius: 12px;
  background-color: #111111;
  padding: 14px;
  margin-top: 80px;
}
.blurred_back .main_div .cross_container
{
  float: right;
}
.blurred_back .main_div .cross_container button
{
  padding: 0px;
  background-color: transparent;
  border: none;
}
.blurred_back .main_div .card_input_forms
{
  padding: 10px 16px;
}
@media screen and (max-width: 767px)
{
  
  .blurred_back .main_div
  {
    padding-top: 180px;
    position: absolute !important;
    min-height: 100vh;
    overflow: scroll;
    background-color: #04adff;
  }
} */
.dark_modal_style
{
  background-color: #111;
  padding: 10px;
}
.dark_modal_style .cross_container
{
  float: right;
}
.dark_modal_style .cross_container button
{
  padding: 0px;
  background-color: transparent;
  border: none;
}
.dark_modal_style .dark_form_field label
{
  font-size: 0.7em !important;
  line-height: 0.9em !important;
}
.generic_error
{
  color: #FF4242 !important;
}
.stripe_logo_long
{
  padding-top: 16px;
}
.stripe_logo_long img
{
  width: 85%;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .dark_modal_style
  {
    padding: 0px;
  } 
}
/** Paynow page currently deffered **/
.pay_now
{
  padding: 120px 0px 80px;
  min-height: 85vh;
}
.pay_now .pay_card_section
{
  background-color: #000;
  padding: 20px 24px 28px;
  border-radius: 8px;
}
.pay_now .pay_card_section .uppercase_text
{
  text-transform: uppercase;
  font: 300 16px/18px "Poppins", sans-serif !important;
  color: #fff9;
}
.pay_now .pay_card_section .details_text
{
  text-transform: uppercase;
  font: 300 16px/18px "Poppins", sans-serif !important;
  color: #fff;
}
.pay_now .color_price
{
  font: 700 48px/56px "Poppins", sans-serif;
  background: -webkit-linear-gradient(268deg, #e0499b 50%, #f3dd59 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 30px;
}

.pricing_details_page
{
  padding: 120px 0px 80px;
  min-height: 85vh;
}

.testimonial_heading_container
{
  padding-top: 1rem;
  padding-bottom: 0.2rem;
}

@media screen and (max-width: 767px) {
  .testimonial_heading_container
  {
    padding-top: 0.2rem;
    padding-bottom: 0.1rem;
  } 
}

/** Modal Popup for API Key **/
.check_email_section
{
  width: 100%;
  /* max-width: 280px; */
  padding: 20px 16px 16px;
  font: 300 18px/28px "Poppins", sans-serif !important;
  letter-spacing: 0px;
  color: #fff;
}
.check_email_section p b
{
  font-weight: 600;
}
.btn_solid_xs {
  display: inline-block;
  padding: 0.4rem 0.5rem 0.4rem;
  min-width: 56px;
  
  border: none;
  border-radius: 2.5rem;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  color: #fff;
  font: 600 0.8rem/1.2rem "Poppins", sans-serif;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.2s;
}

.btn_solid_xs:hover {
  transform: scale(1.05);
  color: #fff;
}

.credit_used
{
  padding: 120px 0px;
}
.credit_used .each_table
{
  padding: 48px 0px 20px;
}
.credit_used .each_table .table_title
{
  font: 600 1.1rem/1.5rem "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #fffd;
  margin-bottom: 18px;
}
.credit_used .table_head
{
  background-color: #000;
  padding: 24px 16px;
  border-radius: 8px;
  font: 400 1rem/1.5rem "Poppins",sans-serif;
  color: #fffd;
}
.credit_used .table_body
{
  padding: 24px 16px 8px;
  border-radius: 8px;
  font: 400 1rem/1.5rem "Poppins",sans-serif;
  color: #fffd;
  word-wrap: break-word;
}
.credit_used .table_body .color_get
{
  color: #7BFF78;
}
.credit_used .table_body .color_post
{
  color: #04ADFF;
}
.credit_used .table_body .color_delete
{
  color: #FF3636;
}

@media screen and (max-width: 767px) {
  .credit_used .table_head
  {
    padding: 20px 16px;
    font: 400 0.8rem/1.2rem "Poppins",sans-serif;
  }
  .credit_used .table_body
  {
    padding: 18px 16px;
    font: 400 0.8rem/1.2rem "Poppins",sans-serif;
    word-wrap: break-word;
  }
}

/* Fields for Stripe integration */
.stripe_form_2
{
  padding: 4px 0px !important;
}
.cst_form_group
{
  background: linear-gradient(90.8deg, #E0499B 3.34%, #5965F3 100%);
  padding: 2px;
  border-radius: 30px;
  
}
.cst_form_group div
{
  padding: 2px;
  background-color: #111111;
  border-radius: 28px;
}
.cst_form_group div input
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
  font: 300 16px/18px "Poppins", sans-serif !important;
  letter-spacing: 0px;
}
.cst_form_group div input:focus
{
  border: none !important;
  box-shadow: none;
}
.cst_form_group div select
{
  /* background-color: transparent !important; */
  border: none !important;
  color: #ffffffdd !important;
  font: 300 16px/18px "Poppins", sans-serif !important;
  letter-spacing: 0px;
  z-index: 2;
}
.cst_form_group div select:focus
{
  border: none !important;
  box-shadow: none;
}

.form_grad_dropdowns_only
{
  background: #E33535;
  padding: 2px;
  border-radius: 25px;
}
.form_grad_dropdowns_only .form_grad_dropdowns_inner
{
  border-radius: 23px;
  background-color: #111;
  padding: 5px 6px 5px 8px;
}
.active_offer_highlight
{
  background: linear-gradient(170.8deg, #E0499B 3.34%, #5965F3 100%);
  padding: 1px ;
  border-radius: 10px;
}
.connect_wallet_container
{
  background-color: #000;
  padding: 20px 16px 32px;
  border-radius: 10px;
  
}

.connect_wallet_title{
  color: #ffffffbb !important;
  font: 600 22px/28px "Avenir-black", sans-serif !important;
  letter-spacing: 0.03em;
  padding: 4px 0px 10px;
}
.connect_wallet_subtitle {
  color: #fffb !important;
  font: 300 15px/18px "Poppins", sans-serif !important;
  padding-bottom: 10px;
}
.btn_solid_grad.wallet_sm {
  display: flex;
  justify-content: center;
  /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  min-width: 180px;
  border: none;
  border-radius: 2.5rem;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  color: #fff;
  font: 600 0.8rem/1.2rem "Poppins", sans-serif;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.2s;
}



.nft_offer_campaign .offer_section_heading
{
  font: 700 24px/36px "Avenir-black",sans-serif;
  color: #fffb;
  letter-spacing: 0.04rem;
}
.nft_offer_campaign .offer_section_subheading
{
  font: 300 16px/24px "Poppins",sans-serif;
  color: #fffb;
}
.nft_offer_campaign .offer_list
{
  padding: 24px 0px 0px;
}

.nft_offer_campaign .each_offer
{
  background-color: black;
  border-radius: 11px;
  padding: 16px 24px;
  display: flex;
  flex-wrap: wrap;
}
.nft_offer_campaign .each_offer .offer_image {
  width: 220px;
  height: 160px;
  position: relative;
}

.offer_image .offer_image_outer_2 {
  position: relative;
  z-index: 0;
}
.offer_image .offer_image_outer_2 .background_image
{
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  
} 
.offer_image .offer_image_outer_2 .background_image img
{
  width: 230px;
  height: 230px;
}
.offer_image .offer_image_outer_2 .actual_image_area
{
  position: relative;
  z-index: 4;
  width: 130px;
  /* transform: rotate(-12deg); */
  /* top: 23px;
  left: 58px; */
  display: block;
  margin: 0 auto;
  top: 8px;
  background-color: transparent;
  /* padding: 6px 6px 4px 6px; */
  border-radius: 8px;
}
.offer_image .offer_image_outer_2 .actual_image_area .actual_nft_image {
  background-color: #656565;
  border-radius: 8px;
}
.offer_image .offer_image_outer_2 .actual_image_area .actual_nft_image img
{
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 8px;
}
.offer_image .offer_image_outer_2 .actual_image_area .actual_nft_name {
  font: 300 10px/12px "Poppins",sans-serif;
  color: #ddd;
  padding-top: 4px;
}
.each_offer .offer_text_details
{
  flex-grow: 1;
}
.each_offer .offer_text_details .each_offer_name
{
  font: 600 28px/36px "Poppins",sans-serif;
  color: #ddd;
}
.each_offer .offer_text_details .each_offer_name sup.active_offer_indicator
{
  font: 600 12px/36px "Poppins",sans-serif;
  padding: 4px 16px 6px;
  background: linear-gradient(60.8deg, #E0499B 3.34%, #5965F3 100%);
  margin-left: 10px;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
}
.each_offer .offer_text_details .each_offer_name span a
{
  text-decoration: none;
  font: 600 12px/36px "Poppins",sans-serif;
  color: #fff;
  letter-spacing: 0.04rem;
  margin-left: 16px;
  word-wrap: break-word;
  text-overflow: hidden;
  background: -webkit-linear-gradient(280deg, #e0499b 18.12%, #f3dd59 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  cursor:pointer;
}
.each_offer .offer_text_details .each_offer_details
{
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0px;
}
.each_offer .offer_text_details .each_offer_details .offer_text
{
  padding-right: 40px;
  font: 300 18px/32px "Poppins",sans-serif;
  color: #ddd;
}
.each_offer .offer_claim_button
{
  padding: 10px 0px 0px;
}
.each_offer .offer_claim_button a
{
  padding: 8px 24px;
  border-radius: 25px;
  border: 2px solid #fffb;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  background: transparent;
  color: #fff;
  font: 500 0.8rem/1.2rem "Poppins", sans-serif;
  text-decoration: none;
  /* transition: all 0.5s ease; */
}
.each_offer .offer_claim_button a:hover
{
  padding: 8px 24px;
  border-radius: 25px;
  outline: none;
  border: 2px solid #000;
  box-shadow: none;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  color: #fff;
  font: 500 0.8rem/1.2rem "Poppins", sans-serif;
}
.active_offer_button
{
  margin-top: 10px;
  padding: 8px 2px;
  border-radius: 25px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  background: transparent;
  color: #ddd;
  font: 500 0.8rem/1.2rem "Poppins", sans-serif;
  text-decoration: none;
  transition: all 0.5s ease;
}

.each_offer .offer_claim_button_disabled
{
  padding: 8px 0px 0px;
}
.each_offer .offer_claim_button_disabled button
{
  padding: 8px 2px;
  border-radius: 25px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  background: transparent;
  color: #ddd;
  font: 500 0.8rem/1.2rem "Poppins", sans-serif;
  opacity: 0.6;
}
.each_offer .offer_claim_button_disabled img
{
  width: 40px;
  margin-left: 10px;
}
@media screen and (max-width: 1200px) {
  .each_offer .offer_text_details .each_offer_name
  {
    font: 600 18px/26px "Poppins",sans-serif;
    color: #ddd;
  }
  .each_offer .offer_text_details .each_offer_name span a
  {
    text-decoration: none;
    font: 300 10px/14px "Poppins",sans-serif;
    color: #ddd;
    letter-spacing: 0.04rem;
    margin-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .each_offer .offer_text_details .each_offer_details .offer_text
  {
    padding-right: 40px;
    padding-top: 10px;
    font: 300 14px/24px "Poppins",sans-serif;
    color: #ddd;
  }
  .claim_offer_modal_body .each_offer .offer_image {
    width: 250px;
    height: 180px;
    position: relative;
    margin: 0 auto;
    margin-top: 26px;
  }
  .nft_offer_campaign .each_offer .offer_image {
    width: 230px;
    height: 180px;
    position: relative;
    margin: 0 auto;
    margin-top: 26px;
  }

}

.each_offer .active_offer_now
{
  padding: 10px 0px 0px;
}
.each_offer .active_offer_now a
{
  padding: 8px 24px;
  border-radius: 25px;
  /* border: 2px solid #fffb; */
  outline: none;
  box-shadow: none;
  background: linear-gradient(60.8deg, #E0499B 3.34%, #5965F3 100%);
  color: #ddd;
  font: 500 0.8rem/1.2rem "Poppins", sans-serif;
  text-decoration: none;
  /* transition: all 0.5s ease; */
}

.wallet_sm_disc {
  display: flex;
  justify-content: center;
  /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border: none;
  border-radius: 2.5rem;
  background-color: rgba(89, 101, 243, 0.29);
  background: linear-gradient(
    90.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  );
  color: #ddd;
  font: 600 0.8rem/1.2rem "Poppins", sans-serif;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.2s;
  transform: scale(0.7);
  margin-top: -10px;
  margin-left: -2px;
}

.orange_links
{
  font: 600 15px/18px "Poppins", sans-serif;
  background: -webkit-linear-gradient(280deg, #e0499b 1.12%, #f3dd59 85%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  cursor:pointer;
}