@import url("../../../resources/css/fonts.css");

.no_decor {
    text-decoration: none !important;
}

.show_on_mobile {
    display: none;
}

@media screen and (max-width: 767px) {
    .show_on_mobile {
        display: flex;
    }

    .show_on_desktop {
        display: none;
    }
}

.show_on_tab {
    display: none;
}

.show_on_desktop_2 {
    display: block;
}

@media screen and (max-width: 992px) {
    .show_on_tab {
        display: flex;
    }

    .show_on_desktop_2 {
        display: none;
    }
}

/*New Pricing Plans*/
.pricing_free_card {
    background-color: #000;
    padding: 32px 32px;
    border-radius: 16px;
}

.free_plan_heading {
    font: 600 36px/40px "Jost","Poppins",sans-serif;
    color: #fff;
    
}

.free_plan_heading span {
    font: 200 14px/40px "Jost","Poppins",sans-serif;
    color: #fff;
    
    margin-left: 4px;
}

.pricing_grad_btn {
    background: linear-gradient(90.8deg,
            rgba(224, 73, 155, 1) 3.34%,
            rgba(89, 101, 243, 1) 100%);
    border: none;
    font: 400 14px/40px "Jost","Poppins",sans-serif;
    color: #fff;
    padding: 8px 40px !important;
    text-decoration: none;
    border-radius: 20px;
    min-width: 300px;
    
    cursor: pointer;
}

.pricing_grad_btn:hover {
    color: #fff;
    transform: scale(1.1);
    cursor: pointer;
}

.pricing_outline_btn {
    background: linear-gradient(90.8deg,
            rgba(224, 73, 155, 1) 3.34%,
            rgba(89, 101, 243, 1) 100%);
    padding: 2px;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
}

.pricing_outline_btn div {
    background-color: #000;
    padding: 7px 44px !important;
    font: 400 14px "Jost","Poppins",sans-serif;
    color: #fff;
    text-align: center;
    border-radius: 20px;
    
}

div.pricing_outline_btn {
    max-width: 200px;
}

.long_text {
    width: 370px;
    margin-left: -2px;
    padding-right: 10px;
}

.normal_text {
    width: 220px;
    padding-left: 12px;
    padding-right: 10px;
}
.short_text
{
  width: 220px;
  padding-left: 12px;
  padding-right: 10px;
}

.pricing_free_card .point_text {
    color: #fff;
    font: 200 16px/28px "Jost","Poppins",sans-serif;
    
    word-wrap: break-word;
}
.pricing_free_card .point_text.em
{
  font: 700 17px/28px "Poppins", sans-serif !important;
  background: -webkit-linear-gradient(275deg, #e0499b 10%, #f3dd59 85%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* color:#0AEEFF; */
}

@media screen and (max-width: 600px) {
    .long_text {
        width: 100%;
        margin-left: 8px;
    }

    .free_plan_heading {
        font: 600 28px/34px "Jost","Poppins",sans-serif;
        text-align: center;
    }

    .free_plan_heading span {
        font: 200 12px/34px "Jost","Poppins",sans-serif;
        margin-left: 4px;
    }

    .pricing_free_card .point_text {
        color: #fff;
        font: 200 14px/24px "Jost","Poppins",sans-serif;
        
        word-wrap: break-word;
    }
}

.paid_cards_container {
    padding-top: 20px;
}

.paid_card_container {
    width: initial;
    padding: 20px 0px;

}

.paid_card_container .paid_card {
    width: 400px;
    min-height: 610px;
    /* background-color: #212121; */
    background-color: #000;
    border-radius: 18px;
    padding: 30px 12px;
}

.paid_card .image {
    height: 100px;
}

.paid_card .image img {
    display: block;
    margin: 0 auto;
    width: 210px;
    /* filter: grayscale(100%); */
}

.paid_card .plan_title {
    font: 400 20px/28px "Jost","Poppins",sans-serif;
    
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    padding-top: 16px;
}

.paid_card .price_indicator_section {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.paid_card .price_indicator_section .cost_text {
    font: 700 48px/49px "Avenir-black", sans-serif;
    color: #fff;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.paid_card .price_indicator_section .cost_text .decimal {
    font: 300 16px "Poppins", sans-serif !important;
    margin-top: 2px;
    margin-left: 4px;
}

.paid_card .price_indicator_section .price_line {
    padding: 2px;
    margin: 0px 12px;
}

.paid_card .price_indicator_section .price_line div {
    height: 44px;
    width: 2px;
    border-radius: 1px;
    background-color: #fff;
}

.paid_card .price_indicator_section .permonth_text {
    font: 200 18px/26px "Poppins", sans-serif !important;
    color: #fff;
    
    text-transform: uppercase;
}

.paid_card .point_section {
    padding: 24px 16px;
    min-height: 240px;
}

.paid_card .point_section .each_point {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    padding: 8px 2px;
}

.paid_card .point_section .each_point .tick {
    margin-right: 16px;
}

.paid_card .point_section .each_point .text {
    font: 200 16px/24px "Poppins", sans-serif !important;
    color: #fff;
    
    padding-top: 1px;
}

.paid_card .point_section .each_point .text.em {
    font: 700 16px/24px "Poppins", sans-serif !important;
    background: -webkit-linear-gradient(275deg, #e0499b 10%, #f3dd59 85%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
    /* color:#0AEEFF; */
}

.paid_card .button_section {
    min-height: 50px;
    text-align: center;
}

.grad_dot {
    background: #D9D9D9;
    height: 16px;
    width: 16px;
    border: none;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 8px;
}

.credit_indicator {
    padding: 10px 30px;
    background-color: #000;
    max-width: 250px;
    border-radius: 10px;
    margin-bottom: 20px;
    font: 400 16px/22px "Poppins", sans-serif;
    color: #fff;
}

@media screen and (max-width: 1600px) {
    .paid_card_container .paid_card {
        width: 350px;

    }

    .paid_card .point_section {
        min-height: 200px;

    }
}

@media screen and (max-width: 1402px) and (min-width: 1200px) {
    .paid_card_container {
        width: 30%;
        padding: 20px 0px;

    }

    .paid_card_container .paid_card {
        width: 100%;
        padding: 30px 8px;
    }

    .paid_card .image {
        height: 80px;
    }

    .paid_card .image img {
        width: 180px;
    }

    .paid_card .plan_title {
        font: 400 16px/22px "Poppins", sans-serif;
        padding-top: 14px;
    }

    .paid_card .price_indicator_section .cost_text {
        font: 700 40px/46px "Avenir-black", sans-serif;
        color: #fff;
        
    }

    .paid_card .price_indicator_section .cost_text .decimal {
        font: 300 16px "Poppins", sans-serif !important;

    }

    .paid_card .price_indicator_section .permonth_text {
        font: 200 14px/20px "Poppins", sans-serif !important;
    }

    .paid_card .point_section {
        padding: 24px 12px;
        min-height: 200px;
        max-width: 90%;
        margin: 0 auto;
    }

    .paid_card .point_section .each_point .text {
        font: 200 13px/20px "Poppins", sans-serif !important;
        color: #fff;
        
    }

    .paid_card .point_section .each_point .text.em {
        font: 600 13px/20px "Poppins", sans-serif !important;

    }

    .paid_card .point_section .each_point .tick {
        margin-right: 8px;
    }

}

@media screen and (max-width: 1199px) {
    .paid_card_container {
        width: 50%;
        padding: 20px 20px;

    }

    .paid_card_container .paid_card {
        width: 100%;
        padding: 30px 8px;
    }

    .paid_card .image {
        height: 80px;
    }

    .paid_card .image img {
        width: 180px;
    }

    .paid_card .plan_title {
        font: 400 16px/22px "Poppins", sans-serif;
        padding-top: 14px;
    }

    .paid_card .price_indicator_section .cost_text {
        font: 700 42px/46px "Avenir-black", sans-serif;
        color: #fff;
        
    }

    .paid_card .price_indicator_section .cost_text .decimal {
        font: 300 16px "Poppins", sans-serif !important;
    }

    .paid_card .price_indicator_section .permonth_text {
        font: 200 14px/20px "Poppins", sans-serif !important;
    }

    .paid_card .point_section {
        padding: 24px 12px;
        min-height: 120px;
        max-width: 75%;
        margin: 0 auto;
    }

    .paid_card .point_section .each_point .text {
        font: 200 14px/20px "Poppins", sans-serif !important;
        color: #fff;
        
    }

    .paid_card .point_section .each_point .text.em {
        font: 600 14px/20px "Poppins", sans-serif !important;
    }

    .paid_card .point_section .each_point .tick {
        margin-right: 8px;
    }
}

@media screen and (max-width: 992px) {
    .paid_card_container {
        width: 100%;
        padding: 20px 0px;

    }
}
@media screen and (max-width: 576px) {
    .paid_card .point_section {
        padding: 24px 12px;
        min-height: 120px;
        max-width: 80%;
        margin: 0 auto;
    }
}

.enterprise_plans {
    margin-top: 24px;
    background-color: #000;
    padding: 32px;
    border-radius: 25px;
}

.enterprise_plans .dashboard_main_title {
    color: #fff;
}

.free_plan_heading {
    font: 600 36px/40px "Poppins", sans-serif;
    color: #fff;
    
}

.enterprise_sub_heading {
    font: 200 16px/28px "Poppins", sans-serif;
    color: #fff;
    

}

@media screen and (max-width: 1099px) {
    .enterprise_sub_heading {
        font: 200 14px/24px "Poppins", sans-serif;
        color: #fff;
        

    }
}

.calendly_grad_btn {
    background: linear-gradient(90.8deg,
            rgba(224, 73, 155, 1) 3.34%,
            rgba(89, 101, 243, 1) 100%);
    border: none;
    font: 400 14px/40px "Poppins", sans-serif;
    color: #fff;
    padding: 0px !important;
    text-decoration: none;
    border-radius: 20px;
    min-width: 200px;
    
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.calendly_grad_btn:hover
{
    transform: scale(1.05);
}


.success_popup .payment_success_modal
{
    display: block;
    margin: 0 auto;
    padding: 20px;
}
.success_popup .payment_message
{
    font: 500 18px/24px "Poppins", sans-serif;  
    color: #fff;
    
    text-align: center;
    padding: 2px 2px 25px;
}

.dashboard_main_title_anchor
{
    background: linear-gradient(90.8deg,
            rgba(224, 73, 155, 1) 3.34%,
            rgba(89, 101, 243, 1) 100%);
    margin-left: 20px;
    padding: 3px 12px 3px;
    font: 500 12px/14px "Poppins", sans-serif;  
    margin-top: -4px !important;
    text-decoration: none;
    color: #D9D9D9;
    border-radius: 10px;
    cursor: pointer;
}
.dashboard_main_title_anchor:hover
{
    color: #fff;
}
