
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #111111;
    color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    line-height: 1.6;
} */

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Hero Section */
.hero {
    background-color: #111111;
    padding: 80px 0 20px;
    text-align: center;
    position: relative;
}

.hero h1 {
    font-size: 48px;
    margin-bottom: 20px;
    font-weight: bold;
}

.hero p {
    font-size: 20px;
    color: #aaa;
    max-width: 700px;
    margin: 0 auto 40px;
}

/* Buttons */
/* Buttons */
.button_group {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
}

.button_primary {
    background-color: #ef4444;
    color: white;
    padding: 12px 32px;
    border-radius: 8px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button_primary:hover {
    background-color: #dc2626;
}

.button_secondary {
    background-color: transparent;
    color: white;
    padding: 12px 32px;
    border-radius: 8px;
    border: 1px solid #4b5563;
    font-weight: 600;
    cursor: pointer;
    transition: border-color 0.3s;
}

.button_secondary:hover {
    border-color: #6b7280;
}

/* Features Section */
.features {
    padding: 30px 0 80px;
    background-color: #111111;
}

.features_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 40px;
}

.feature_card {
    background-color: #1a1a1a;
    padding: 30px;
    border-radius: 12px;
    transition: transform 0.3s;
}

.feature_card:hover {
    transform: translateY(-5px);
}

.feature_icon {
    background-color: rgba(239, 68, 68, 0.1);
    color: #ef4444;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.feature_card h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.feature_card p {
    color: #aaa;
}

/* Pricing Section */
.pricing {
    padding: 60px 0;
    background-color: #111111;
    text-align: center;
}

.pricing_card {
    background-color: #1a1a1a;
    padding: 40px;
    border-radius: 16px;
    max-width: 500px;
    margin: 40px auto;
}

.price {
    font-size: 40px;
    font-weight: bold;
    margin: 20px 0;
}

.price span {
    font-size: 20px;
    color: #aaa;
}

/* Features List */
.features_list {
    text-align: left;
    margin: 30px 0;
}

.features_list li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: #aaa;
}

.features_list li::before {
    content: "✓";
    color: #ef4444;
    margin-right: 10px;
}

/* Section Headers */
.section_title {
    /* font-size: 36px; */
    margin-bottom: 20px;
    text-align: center;
    font-weight: 700;
}

.section_description {
    color: #aaa;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 36px;
    }

    .button_group {
        flex-direction: column;
        align-items: stretch;
    }

    .features_grid {
        grid-template-columns: 1fr;
    }
}