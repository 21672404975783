.full_overlay
{
  position: fixed;
  height: 120vh;
  width: 100vw;
  z-index: 100;
  background-color: #21212122;
  overflow: hidden !important;
  margin-top: -150px;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader
{
  width: 240px;
  height: 160px;
  border-radius: 16px;
  background: #000;
  border: 2px solid #E33535;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 2px;
  z-index: 101 !important;
}
.loader .loading_image
{
    width: 80px;
    display: block;
    margin: 0 auto;
}
.type_text
{
    padding-top: 10px;
    font: 500 15px/22px "Jost",sans-serif;
    color: #fff;
    width: 220px;
    text-align: center;
}