@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

@font-face {
    font-family: Avenir-black;
    src: url('../fonts/AvenirLTProBlack.otf');
}

@font-face {
    font-family: Avenir-bold;
    src: url('../fonts/AvenirLTProHeavy.otf');
}

@font-face {
    font-family: Avenir-med;
    src: url('../fonts/AvenirLTProMedium.otf');
}

@font-face {
    font-family: Avenir-light;
    src: url('../fonts/AvenirLTProLight.otf');
}