/* @import url('https://fonts.googleapis.com/css2?family=Archivo:wght@200;300;400;500;600;700&display=swap');*/
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');  */

html,
body {
    padding: 0px;
    margin: 0;
    max-width: 100vw;
    overflow-x: hidden;
    background-color: #111;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
.white_link {
    color: inherit;
    text-decoration: none;
}
.white_link:hover {
    color: #fff;
    text-decoration: none;
}
.border_moving {
    width: 100vw;
    background: linear-gradient(90deg, #fff 50%, transparent 50%);
    background-repeat: repeat-x;
    background-size: 24px 2px;
    background-position: 0px 0px;
    padding: 6px 0px;
    /* animation: border-dance 0.4s infinite linear; */
}
@keyframes border-dance {
    0% {
        background-position: 0px 0px;
    }
    100% {
        background-position: 24px 0px;
    }
}
.border_moving_reverse {
    width: 100vw;
    background: linear-gradient(90deg, #fff 50%, transparent 50%);
    background-repeat: repeat-x;
    background-size: 22px 3px;
    background-position: 0px 0px;
    padding: 6px 0px;
    /* animation: border-dance-reverse 0.4s infinite linear; */
}
@keyframes border-dance-reverse {
    0% {
        background-position: 22px 0px;
    }
    100% {
        background-position: 0px 0px;
    }
}
.rolling_each_text {
    padding: 10px 24px;
    font: 600 32px 'Kanit', sans-serif;
    color: #fff;
    text-align: center;
}
@media screen and (max-width: 767px) {
    .rolling_each_text {
        padding: 10px 18px;
        font: 600 24px 'Kanit', sans-serif;
    }
}
.rolling_each_image {
    padding: 10px 24px;
}
.smokey_back {
    background: url('./resources/img/smokey-background.png');
    background-size: 100% 100%;
    /* animation: animate 30s linear infinite; */
}
@keyframes animate {
    0% {
        background-position-x: 0%;
    }
    100% {
        background-position-x: 100vw;
    }
}

/* Test Styles */
/* Testimonial Section */
.no_decor {
    text-decoration: none;
}

.testimonial_section {
    padding: 2px 0px 0px;
    overflow: hidden;
}

.each_testimonial {
    padding: 20px 28px;
    border-radius: 48px;
    position: relative;
    width: 100%;
    background-color: #111111;
    border: 4px dotted grey;
    min-height: 400px;
    margin: 0px 16px;
    margin-top: 5px;
}
.each_testimonial::before {
    content: '';
    height: 108px !important;
    width: 90px !important;
    background: url('./resources/img/commaIconDark.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    z-index: 99;
    top: -40px;
    left: 22px;
    transition: all 0.2s ease;
}
.each_testimonial:hover {
    border: 4px dotted #e33535;
}
.each_testimonial:hover::before {
    content: '';
    height: 108px !important;
    width: 90px !important;
    background: url('./resources/img/commaIcon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    z-index: 99;
    top: -40px;
    left: 22px;
}
.no_decor:hover .each_testimonial {
    border: 4px dotted #e33535;
}
.each_testimonial .quote_foreground {
    position: absolute;
    top: -16px;
}
.each_testimonial .test_content {
    margin-top: 40px;
}
.each_testimonial .identity_details .photo {
    padding-right: 8px;
}
.each_testimonial .identity_details .photo img {
    height: 48px;
    width: 48px;
    object-fit: cover;
}

.each_testimonial .identity_details .name {
    font: 400 22px/26px 'Poppins', sans-serif;
    color: #fff;
}
.each_testimonial .identity_details .verified {
    padding: 0px 6px;
}
.each_testimonial .identity_details .handle {
    font: 200 12px/26px 'Poppins', sans-serif;
    color: #fff;
    font-style: italic;
}
.each_testimonial .comment {
    font: 200 14px/22px 'Poppins', sans-serif;
    color: #fff;
    letter-spacing: 1px;
    margin-top: 18px;
}
@media screen and (max-width: 767px) {
    .each_testimonial .comment {
        font: 200 13px/18px 'Jost', sans-serif !important;
    }
}
.grad_dot {
    background: #d9d9d9;
    height: 16px;
    width: 16px;
    border: none;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 8px;
}

.owl-dots .active {
    background-color: #fbb901 !important;
}

@media screen and (max-width: 767px) {
    .each_testimonial .comment {
        font: 200 13px/20px 'Poppins', sans-serif;
    }
    .testimonial_section {
        padding-bottom: 24px;
    }
}

.team_member {
    width: 100%;
    max-width: 280px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 80px;
}
.team_member_small_row {
    margin-left: 20px;
    margin-right: 20px;
}
.team_member .member_name {
    font: 500 24px 'kanit', sans-serif;
    color: #f4f4f4;
    text-align: center;
}
.team_member .member_post {
    font: 500 16px 'Jost', sans-serif;
    color: #f4f4f4;
    text-align: center;
}
.team_member .member_bio {
    font: 300 14px/24px 'Jost', sans-serif;
    color: #f4f4f4;
    text-align: center;
}
.team_member .member_icon {
    padding: 0px 10px;
    color: #fff;
}
.each_plan {
    border-radius: 24px;
}
.each_plan:hover {
    border-color: #e33535;
}
.each_plan_button {
    color: #f4f4f4 !important;
    border-color: #e33535;
}
.each_plan:hover .each_plan_button {
    background-color: #e33535;
    color: #f4f4f4;
}
.wave-animation {
    position: relative;
    width: 100%;
    height: 800px;
    background-color: #111;
    background-size: cover;
    background-attachment: fixed;
}
.wave-animation .wave {
    position: absolute;
    width: 100%;
    height: 800px; /*PNG*/
    top: 0;
    left: 0;
    background-image: url('./resources/img/wave-back-main.png');
    animation: anim 30s linear infinite;
}
.wave-animation .wave:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 800px;
    top: 0;
    left: 0;
    background-image: url('./resources/img/wave-back-main.png');
    opacity: 0.4;
    animation: anim-rev 30s linear infinite;
}
.wave-animation .wave:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 800px;
    top: 0;
    left: 0;
    background-image: url('./resources/img/wave-back-main.png');
    opacity: 0.6;
    animation-delay: -5s;
    animation: anim 50s linear infinite;
}
@keyframes anim {
    0% {
        background-position: 0;
    }
    100% {
        background-position: 1360px;
    }
}
@keyframes anim-rev {
    0% {
        background-position: 1360;
    }
    100% {
        background-position: 0px;
    }
}

.wave-animation-2 {
    position: relative;
    width: 100%;
    height: 800px;
    background-color: #111;
    background-size: cover;
    background-attachment: fixed;
}
.wave-animation-2 .wave {
    position: absolute;
    width: 100%;
    height: 800px; /*PNG*/
    top: 0;
    left: 0;
    background-image: url('./resources/img/wave-back-main-dark.png');
    animation: anim 30s linear infinite;
}
.wave-animation-2 .wave:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 800px;
    top: 0;
    left: 0;
    background-image: url('./resources/img/wave-back-main-dark.png');
    opacity: 0.4;
    animation: anim-rev 30s linear infinite;
}
.wave-animation-2 .wave:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 800px;
    top: 0;
    left: 0;
    background-image: url('./resources/img/wave-back-main-dark.png');
    opacity: 0.6;
    animation-delay: -5s;
    animation: anim 50s linear infinite;
}

.wave-animation-3 {
    position: relative;
    width: 100%;
    height: 800px;
    background-color: #111;
    background-size: cover;
    background-attachment: fixed;
}
.wave-animation-3 .wave {
    position: absolute;
    width: 100%;
    height: 800px; /*PNG*/
    top: 0;
    left: 0;
    background-image: url('./resources/img/wave-back-main-dark-mid.png');
    animation: anim 30s linear infinite;
}
.wave-animation-3 .wave:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 800px;
    top: 0;
    left: 0;
    background-image: url('./resources/img/wave-back-main-dark-mid.png');
    opacity: 0.4;
    animation: anim-rev 30s linear infinite;
}
.wave-animation-3 .wave:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 800px;
    top: 0;
    left: 0;
    background-image: url('./resources/img/wave-back-main-dark-mid2.png');
    opacity: 0.6;
    animation-delay: -5s;
    animation: anim 50s linear infinite;
}

.super-canvas {
    width: 80%;
    /* max-width: 600px; */
    aspect-ratio: 3/2;
    margin: 0 auto;
}
.square-canvas {
    width: 80%;
    /* max-width: 600px; */
    aspect-ratio: 1/1;
    margin: 0 auto;
}
.pricing-canvas {
    width: 100%;
}
.calendly_button {
    background-color: #e33535;
    color: #f4f4f4;
    padding: 8px 30px 10px !important;
    border-radius: 24px !important;
    /* width: 180px; */
    font: 600 14px/16px 'Kanit', sans-serif;
    transition: all 0.4s ease;
}
.calendly_button:hover {
    background-color: #f4f4f4;
    color: #e33535;
}

@media screen and (max-width: 767px) {
    .calendly_button {
        padding: 10px 24px 12px !important;
        border-radius: 24px !important;
        font: 600 13px/13px 'Kanit', sans-serif;
    }
}
.calendly_button_2 {
    background-color: #000;
    color: #f4f4f4;
    padding: 12px 30px 14px !important;
    border-radius: 24px !important;
    /* width: 180px; */
    font: 600 16px/16px 'Kanit', sans-serif;
    transition: all 0.4s ease;
}
.calendly_button_2:hover {
    background-color: #f4f4f4;
    color: #000;
}

@media screen and (max-width: 767px) {
    .calendly_button_2 {
        padding: 10px 24px 12px !important;
        border-radius: 24px !important;
        font: 600 13px/13px 'Kanit', sans-serif;
    }
}

.calendly_button_red_outline {
    background-color: #000;
    color: #f4f4f4;
    padding: 10px 30px 12px !important;
    border-radius: 24px !important;
    border: 1px solid #e33535;
    /* width: 180px; */
    font: 600 16px/16px 'Kanit', sans-serif;
    transition: all 0.4s ease;
}
.calendly_button_red_outline:hover {
    background-color: #e33535;
    color: #f4f4f4;
}

.wallet_connect {
    background-color: #e33535;
    color: #f4f4f4;
    padding: 12px 30px 14px !important;
    border-radius: 24px !important;
    /* width: 180px; */
    font: 600 16px/16px 'Kanit', sans-serif;
}
@media screen and (max-width: 767px) {
    .wallet_connect {
        font: 600 14px/14px 'Kanit', sans-serif;
        padding: 4px 30px 6px !important;
    }
}

.calendly_button_float {
    display: inline-block;
    position: fixed;
    width: 60px;
    height: 60px;
    background: url('./resources/img/phone-call.svg'), #e33535;
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;
    background-size: 40%, cover;
    color: #fff;
    border-radius: 2.5rem;
    z-index: 99;
    bottom: 20px;
    right: 20px;
    border: 2px solid #f4f4f4;
    transition: all 0.4s ease;
}
.calendly_button_float:hover {
    background: url('./resources/img/phone-call.svg'), #fbb901;
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;
    background-size: 40%, cover;
}
/* .calendly_button_float::before {
    content: "Hellow WOrld";
} */

.postman_styles {
    background-color: #ff6c37;
    background: linear-gradient(90.8deg, #ff6c37 3.34%, #ff6c37 100%) !important;
    margin-top: 1px;
    height: 40px !important;
    width: 158px !important;
    text-align: center;
    padding-top: 7px;
    border-radius: 25px !important;
    transition: all 0.5s ease-in;
}
.postman_styles::before {
    content: 'Run in Postman';
    font: 500 0.8rem/1rem 'Kanit', sans-serif;
    color: #fff;
    transition: all 0.3s ease;
}
.postman_styles:hover {
    background-color: #fff;
    background: linear-gradient(90.8deg, #fff 3.34%, #fff 100%) !important;
}
.postman_styles:hover::before {
    color: #ff6c37;
}
@media screen and (max-width: 576px) {
    .postman_styles {
        display: none !important;
        margin-top: 1px;
        height: 40px !important;
        width: 158px !important;
        text-align: center;
        padding-top: 7px;
        border-radius: 25px !important;
    }
    .postman_styles::before {
        content: 'Run in Postman';
        font: 500 0.8rem/1rem 'Kanit', sans-serif;
        color: #fff;
        transition: all 0.3s ease;
    }
}

.context {
    width: 100%;
    position: absolute;
    top: 50vh;
}

.context h1 {
    text-align: center;
    color: #fff;
    font-size: 50px;
}

.area {
    background: #111;
    background: -webkit-linear-gradient(to left, #000, #111);
    width: 100%;
    height: 100vh;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.static_wave_backdrop {
    /* background: #111;   */
    /* background: -webkit-linear-gradient(to left, #000, #111); */
    background: url('./resources/img/smokey-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    position: relative;
    margin-top: 0px;
    z-index: 0;
}
@media screen and (min-width: 767px) {
    .static_wave_backdrop {
        min-height: 800px;
    }
}

.inside_dot {
    background: #d9d9d966;
    height: 8px;
    width: 8px;
    border: none;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 8px;
    transition: all 0.4s ease;
}
.inside_dot.active {
    background: #ffffffcc;
    height: 14px;
    width: 14px;
}
.inside_controls {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 120px !important;
    z-index: 2;
    margin-top: -80px;
}

.calendly_button_common {
    background-color: transparent;
    border: 1px solid #e33535;
    text-align: center;
    font: 500 15px/14px 'Kanit', sans-serif;
    color: #fff;
    padding: 11px 10px 12px;
    width: 170px;
    border-radius: 20px;
    transition: all 0.2s ease;
}
.calendly_button_common::before {
    content: 'Contact Us';
}
.calendly_button_common:hover {
    background-color: #e33535;
}

@keyframes red-color-apply {
    0% {
        color: #fff;
        text-shadow: 0px 0px 8px transparent;
    }
    25% {
        color: #e33535;
        text-shadow: 0px 0px 8px #FF0000;
    }
    50% {
        color: #e33535;
        text-shadow: 0px 0px 8px #FF0000;
    }
    75% {
        color: #e33535;
        text-shadow: 0px 0px 8px #FF0000;
    }
    100% {
        color: #fff;
        text-shadow: 0px 0px 8px transparent;
    }
}
.red_text_1 {
    color: #fff;
    text-shadow: 0px 0px 8px transparent;
    animation: red-color-apply 0.6s linear 1;
    animation-delay: 2s;
}
.red_text_2 {
    color: #fff;
    text-shadow: 0px 0px 8px transparent;
    animation: red-color-apply 0.6s linear 1;
    animation-delay: 2.6s;
}
.red_text_3 {
    color: #fff;
    text-shadow: 0px 0px 8px transparent;
    animation: red-color-apply 0.6s linear 1;
    animation-delay: 3.2s;
}


@keyframes red_yellow_anim {
    0% {color: #FF5454;}
    50% {color: #FDF41B;}
    100% {color: #FF5454;}
}

.flashy_text {
    color: #FF5454;
    animation-name: red_yellow_anim;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
}

@keyframes cyan_yellow_anim {
    0% {color: #6CD0D0;}
    50% {color: #FDF41B;}
    100% {color: #6CD0D0;}
}

.flashy_text_2 {
    color: #6CD0D0;
    animation-name: cyan_yellow_anim;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
}
@keyframes pop_dance {
    0% {right: 76px; transform: scale(1);}
    50% {right: 80px; transform: scale(1.05);}
    100% {right: 76px; transform: scale(1);}
}
.pop_dance_anim {
    /* bottom: 28px; */
    animation-name: pop_dance;
    animation-duration: 0.3s;
    animation-iteration-count: 3;
    animation-delay: 0.4s;
}

.overview_table tr th {
    border-color: #e3353566;
    border-style: dotted;
    color: #fbb901;
}

.overview_table tr td {
    border-color: transparent;
    color: #d9d9d9;
    text-align: center;
}
.overview_table tr td.text_left {
    text-align: left !important;
}
.overview_table tr td:last-child {
    color: #d9d9d9;
    font-weight: 500;
    text-align: right;
}
.overview_table tr td:first-child {
    color: #d9d9d9;
    font-weight: 500;
    text-align: left;
}

.overview_table tr:nth-last-child(2) td {
    border-color: #979797;
    border-style: dashed;
}

.overview_table tr:last-child td:last-child {
    color: #fbb901;
    font-weight: 600;
}
.overview_table tr:last-child td:first-child {
    color: #d9d9d9;
    font-weight: 600;
}


.lds-ellipsis {
    /* change color here */
    color: #f0f0f0;
  }
  .lds-ellipsis,
  .lds-ellipsis div {
    box-sizing: border-box;
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33.33333px;
    width: 13.33333px;
    height: 13.33333px;
    border-radius: 50%;
    background: currentColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  /* Responsive based on height */
  .spacer_height {
    height: 25%;
  }
  @media (max-height: 900px) {
    .spacer_height {
        height: 16%;
      }
  }
  @media (max-height: 800px) {
    .spacer_height {
        height: 10%;
      }
  }
  @media (max-height: 767px) {
    .spacer_height {
        height: 0%;
    }
  }
      