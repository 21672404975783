@import url("../../../resources/css/fonts.css");

.dark_modal_style
{
  background-color: #202020;
  padding: 10px;

}
.dark_modal_style .cross_container
{
  float: right;
}
.dark_modal_style .cross_container button
{
  padding: 0px;
  background-color: transparent;
  border: none;
}
.dark_modal_style .claim_offer_modal_body
{
  padding: 0px;
}

.dark_modal_style .claim_offer_modal_body .offer_popup_heading
{
  font: 700 24px/36px "Kanit",sans-serif;
  color: #fffb;
  letter-spacing: 0.04rem;
}
.dark_modal_style .claim_offer_modal_body .offer_popup_subheading
{
  font: 300 16px/24px "Jost",sans-serif;
  color: #fffb;
}
.claim_offer_modal_body .offer_list
{
  padding: 24px 0px 0px;
}
.claim_offer_modal_body .each_offer
{
  background: black;
  padding: 1px;
  margin-bottom: 20px;
  border-radius: 14px;
  /* transition: background 0.1s ease-in; */
}
.claim_offer_modal_body .each_offer_inner
{
  background-color: black;
  border-radius: 14px;
  padding: 16px 24px;
  display: flex;
  flex-wrap: wrap;
}
.claim_offer_modal_body .each_offer .offer_image {
  width: 220px;
  height: 160px;
  position: relative;
}
/* .offer_image .offer_image_outer {
  position: relative;
  z-index: 0;
}
.offer_image .offer_image_outer .offer_image_inner_1
{
  z-index: 5;
  width: 70px;
  position: absolute;
  top: 4px;
  left: 0;
}
.offer_image .offer_image_outer .offer_image_inner_2
{
  z-index: 6;
  position: absolute;
  top: 0;
  left: 55px;
}
.offer_image_inner_2 .nft_image_outer
{
  width: 140px;
  height: 160px;
  padding: 10px;
  padding-bottom: 4px;
  background-color: #202020;
  border-radius: 10px;
  transform: rotate(-8deg);
}
.offer_image_inner_2 .nft_image_outer .nft_image_inner
{
  width: 120px;
  height: 120px;
  border-radius: 10px;
}
.offer_image_inner_2 .nft_image_outer .nft_image_inner img
{
  width: 120px;
  border-radius: 10px;
  object-fit: cover;
}
.offer_image_inner_2 .nft_text_inner{
  font: 300 12px/14px "Poppins",sans-serif;
  color: #fff;
  padding-top: 8px;
}
.offer_image .offer_image_outer .offer_image_inner_3
{
  z-index: 8;
  width: 70px;
  position: absolute;
  top: 0;
  right: 0;
} */


.offer_image .offer_image_outer_2 {
  position: relative;
  z-index: 0;
}
.offer_image .offer_image_outer_2 .background_image
{
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  
} 
.offer_image .offer_image_outer_2 .background_image img
{
  width: 230px;
  height: 230px;
}
.offer_image .offer_image_outer_2 .actual_image_area
{
  position: relative;
  z-index: 4;
  width: 130px;
  display: block;
  margin: 0 auto;
  top: 8px;
  background-color: transparent;
  /* padding: 6px 6px 4px 6px; */
  border-radius: 8px;
}

.offer_image .offer_image_outer_2 .actual_image_area .actual_nft_image img
{
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 8px;
}
.offer_image .offer_image_outer_2 .actual_image_area .actual_nft_name {
  font: 300 10px/12px "Kanit","Poppins",sans-serif;
  color: #ddd;
  padding-top: 4px;
}
.claim_offer_modal_body .each_offer .offer_text_details
{
  flex-grow: 1;
}
.each_offer .offer_text_details .each_offer_name
{
  font: 600 28px/36px "kanit","Poppins",sans-serif;
  color: #ddd;
}
.each_offer .offer_text_details .each_offer_name span a
{
  text-decoration: none;
  font: 600 12px/36px "Kanit","Poppins",sans-serif;
  color: #fff;
  letter-spacing: 0.04rem;
  margin-left: 16px;
  word-wrap: break-word;
  text-overflow: hidden;
  text-decoration: none;
  color: #FBB901;
  text-align: center;
  cursor:pointer;
}
.each_offer .offer_text_details .each_offer_details
{
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0px;
}
.each_offer .offer_text_details .each_offer_details .offer_text
{
  padding-right: 40px;
  font: 300 18px/32px "Jost","Poppins",sans-serif;
  color: #ddd;
}
.each_offer .offer_claim_button
{
  padding: 10px 0px 0px;
}
.each_offer .offer_claim_button button
{
  padding: 8px 24px;
  border-radius: 20px;
  border: 2px solid #E33535;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  /* background: transparent; */
  color: #ddd;
  font: 500 0.8rem/1.2rem "Kanit","Poppins",sans-serif;
  /* transition: all 0.5s ease; */
}
.each_offer:hover
{
  /* background: linear-gradient(
    160.8deg,
    rgba(224, 73, 155, 1) 3.34%,
    rgba(89, 101, 243, 1) 100%
  ); */
  background-color: #E33535;
}
.each_offer:hover .offer_claim_button button
{
  padding: 8px 24px;
  border-radius: 20px;
  outline: none;
  box-shadow: none;
  background-color: #E33535;
  color: #fff;
  /* color: #ddd; */
}

.each_offer .offer_claim_button_disabled
{
  padding: 8px 0px 0px;
}
.each_offer .offer_claim_button_disabled button
{
  padding: 8px 2px;
  border-radius: 25px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  background: transparent;
  color: #ddd;
  font: 500 0.8rem/1.2rem "Jost","Poppins",sans-serif;
  opacity: 0.6;
}
.each_offer .offer_claim_button_disabled img
{
  width: 40px;
  margin-left: 10px;
}
.offer_image .offer_has_been_claimed
{
  width: 230px;
  height: 160px;
  padding-top: 10px;
}
.offer_image .offer_has_been_claimed img
{
  display: block;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .each_offer .offer_text_details .each_offer_name
  {
    font: 600 18px/26px "Jost","Poppins",sans-serif;
    color: #ddd;
  }
  .each_offer .offer_text_details .each_offer_name span a
  {
    text-decoration: none;
    font: 300 10px/14px "Jost","Poppins",sans-serif;
    color: #ddd;
    letter-spacing: 0.04rem;
    margin-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .each_offer .offer_text_details .each_offer_details .offer_text
  {
    padding-right: 40px;
    padding-top: 10px;
    font: 300 14px/24px "Jost","Poppins",sans-serif;
    color: #ddd;
  }
  .claim_offer_modal_body .each_offer .offer_image {
    width: 250px;
    height: 180px;
    position: relative;
    margin: 0 auto;
    margin-top: 26px;
  }

}

.orange_links
{
  font: 600 15px/18px "Poppins", sans-serif;
  color: #FBB901;
  text-align: center;
  cursor:pointer;
}
