.lds_ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 16px;
}

.lds_ellipsis div {
  position: absolute;
  top: 4px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds_ellipsis div:nth-child(1) {
  left: 8px;
  animation: ldsellipsis1 0.6s infinite;
}

.lds_ellipsis div:nth-child(2) {
  left: 8px;
  animation: ldsellipsis2 0.6s infinite;
}

.lds_ellipsis div:nth-child(3) {
  left: 32px;
  animation: ldsellipsis2 0.6s infinite;
}

.lds_ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes ldsellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes ldsellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes ldsellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}


.lds_ripple {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 10px;
  top: -12px;
}

.lds_ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds_ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds_ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds_ripple {
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}