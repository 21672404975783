.sidebar
{
    position: fixed;
    height: 100vh;
    width: 300px;
    background-color: black;
    overflow-x: hidden;
    transition: width 0.2s ease-in-out;
    z-index: 10;
}
.sidebar .menu
{
    padding: 120px 40px 40px;
    overflow: hidden !important;
    transition: opacity 0.1s ease-in-out;
}
.sidebar .menu a
{
    text-decoration: none;
    cursor: pointer;
}
.sidebar .menu .menu_item
{
    color: white;
    padding: 16px 0px;
}
.sidebar .menu .menu_item .text
{
    font: 400 18px/20px "Poppins", sans-serif;
    padding-top: 7px;
    padding-left: 24px;
}
.sidebar .menu .menu_item .text.active
{
    font: 600 18px/20px "Poppins", sans-serif;
}

.sidebar .menu .menu_logout
{
    position: absolute;
    top: 90%;
    width: 60%;
    text-align: center;
    font: 600 18px/20px "Poppins", sans-serif;
    color: #fff;
}

.dark_backdrop_gradient 
{
    background: linear-gradient(174.61deg, #111111 0%, #181818 99.2%);
}
.container_with_sidebar
{
    min-height: 88.5vh;
    padding-left: 300px;
    background-color: #111111;
}
.logout_sidebar
{
    font: 600 18px/20px "Poppins", sans-serif;
    background-color: transparent;
    color: #fff;
    border: none;
}
@media screen and (max-width: 767px) {
    .container_with_sidebar
    {
        padding-left: 0px;
    } 
    .sidebar .menu
    {
        padding: 70px 40px 40px;
    }
    .sidebar
    {
        width: 0px;
    }
    .sidebar .menu .menu_item .text
    {
        font-size: 1rem;
    }
    .sidebar .menu .menu_item .text.active
    {
        font-size: 1rem;
    }
}
