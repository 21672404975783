@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600&family=Kanit:wght@600&display=swap');
.dark_form_group
{
  background: #E33535;
  padding: 2px;
  border-radius: 30px;
  
}
.dark_form_group div
{
  padding: 10px 16px;
  background-color: #111111;
  border-radius: 28px;
}
.dark_form_group div input
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
  font: 300 16px/18px "Jost", sans-serif !important;
  
}
.dark_form_group div input:focus
{
  border: none !important;
  box-shadow: none;
}
.dark_form_group div select
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
  font: 300 16px/18px "Jost", sans-serif !important;
  
  z-index: 2;
}
.dark_form_group div select:focus
{
  border: none !important;
  box-shadow: none;
}

.dark_form_field
{
  padding: 16px 0px;
  position: relative;
}
.dark_form_field.in_user_form
{
  padding: 8px 0px;
}

.dark_form_field label
{
  font: 300 16px/18px "Jost", sans-serif !important;
  
  color: #ffffffdd !important;
  padding-bottom: 12px;
  padding-left: 2px;
}
.dark_form_field .down_icon
{
  position: absolute;
  background-color: transparent;
  color: #fff;
  right: 4px;
  bottom: 18px;
  z-index: 1;
}
.dark_form_field .card_input_clubbed
{
  background: #E33535;
  padding: 2px;
  border-radius: 8px;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner
{
  width: 100%;
  background-color: #111111;
  border-radius: 6px;
  padding: 0px 12px;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner input
{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
  color: #ffffffdd !important;
  font: 300 16px/18px "Jost", sans-serif !important;
  
  padding: 16px 8px;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner input:hover
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner input:focus
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field .card_input_clubbed .card_input_clubbed_inner input:active
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field select
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field select:hover
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field select:focus
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
.dark_form_field select:active
{
  background-color: transparent !important;
  border: none !important;
  color: #ffffffdd !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #111 inset !important;
    -webkit-text-fill-color: #fffd !important;
    border: none;
    color: #fffd;
}
.dark_modal_style
{
  background-color: #111;
  padding: 10px;
}
.dark_modal_style .cross_container
{
  float: right;
}
.dark_modal_style .cross_container button
{
  padding: 0px;
  background-color: transparent;
  border: none;
}
.dark_modal_style .dark_form_field label
{
  font-size: 0.8em ;
  line-height: 0.9em ;
}
.generic_error
{
  color: #FF4242 !important;
}
.stripe_logo_long
{
  padding-top: 16px;
}
.stripe_logo_long img
{
  width: 85%;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .dark_modal_style
  {
    padding: 0px;
  } 
}

.dashboard_main_title
{
  font: 700 22px/28px "Kanit", sans-serif;
  color: #ffffffcc;
  
}
@media screen and (min-width: 1331px) and (max-width: 1600px) {
    .dashboard_main_title
    {
      font: 700 20px/26px "Kanit", sans-serif;
    }
    .dashboard_sub_title
    {
      font: 300 14px/26px "Jost", sans-serif;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1330px) {
    .dashboard_main_title
    {
      font: 400 18px/24px "Kanit", sans-serif;
      color: #ffffffcc;
      
    }
    .dashboard_sub_title
    {
      font: 300 12px/22px "Jost", sans-serif;
    }
  }
  @media screen and (max-width: 767px) {
    .dashboard_main_title
    {
      font: 700 20px/26px "Kanit", sans-serif;
    }
    .dashboard_sub_title
    {
      font: 300 14px/26px "Jost", sans-serif;
    }
  }

  .btn_solid_grad {
    display: inline-block;
    /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    min-width: 200px;
    border: none;
    border-radius: 2.5rem;
    background-color: #E33535;

    color: #fff;
    font: 600 0.8rem/1.2rem "Kanit", sans-serif;
    
    text-align: center;
    text-decoration: none !important;
    transition: all 0.2s;
  }
  
  .btn_solid_grad:hover {
    /* transform: scale(1.1); */
    color: #111;
    background-color: #f4f4f4;
  }
  .btn_outline_gradient_button {
    display: inline-block;
    padding: 2px;
    border: none;
    border-radius: 2.5rem;
    min-width: 200px;
    background-color: #E33535;
    
    font: 600 0.8rem/1.5rem "Kanit", sans-serif;
    letter-spacing: 0.1em;
    transition: all 0.2s;
  }

  .btn_outline_gradient_button .inner_white_area {
    padding: 0.58rem 0.2rem !important;
    background-color: #111;
    border-radius: 2.5rem;
    transition: all 0.2s;
  }
  .btn_outline_gradient_button:hover .inner_white_area {
    /* transform: scale(1.1); */
    background-color: #E33535;
  }
  a.btn_outline_gradient_button {
    display: inline-block;
    padding: 2px;
    border: none;
    border-radius: 2.5rem;
    min-width: 200px;
    background-color: rgba(89, 101, 243, 0.29);
    background: #E33535;
    font: 600 0.8rem/1.5rem "Kanit", sans-serif;
    letter-spacing: 0.1em;
    transition: all 0.2s;
    text-decoration: none;
    color: #fff;
  }
  a.btn_outline_gradient_button .inner_white_area {
    text-align: center;
    color: #fff;
  }
  a.btn_outline_gradient_button.sm {
    display: inline-block;
    padding: 2px;
    border: none;
    border-radius: 2.5rem;
    min-width: 160px;
    background-color: #E33535;
    font: 600 0.8rem/1.2rem "Kanit", sans-serif;
    letter-spacing: 0.1em;
    transition: all 0.2s;
    text-decoration: none;
    color: #fff;
  }
  a.btn_outline_gradient_button.sm .inner_white_area {
    text-align: center;
    color: #fff;
  }
  .p_para_2 {
    font: 300 14px/18px "Jost", sans-serif;
    
    color: #ffffffdd;
  }

  .form_grad_dropdowns_only
{
  background: #E33535;
  padding: 2px;
  border-radius: 25px;
}
.form_grad_dropdowns_only .form_grad_dropdowns_inner
{
  border-radius: 23px;
  background-color: #111;
  padding: 5px 6px 5px 8px;
}
.white_links
{
font-family: "Jost" sans-serif !important;
  color: #fafafa;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}
.white_links:hover
{
  color: #fff;
  
}
.btn_solid_grad_full_width {
    display: inline-block;
    /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    border: none;
    border-radius: 2.5rem;
    background-color: #E33535;
    
    color: #fff;
    font: 600 0.9rem/1.2rem "kanit", sans-serif;
    
    text-align: center;
    text-decoration: none !important;
    transition: all 0.2s;
  }
  
  .btn_solid_grad_full_width:hover {
    /* transform: scale(1.02); */
    color: #111 !important;
    background-color: #f4f4f4;
  }
  .btn_solid_grad_full_width.disabled
  {
    opacity: 0.6;
  }
  .btn_solid_grad_full_width.success
  {
    background: #50C878;
  }
  .btn_solid_grad_full_width.failed
  {
    background: #D70040;
  }
  .btn_solid_grad_full_width.loading
  {
    background: #FF9F00;
  }
  .dark_form_field label
{
  font: 300 16px/18px "Jost", sans-serif !important;
  
  color: #ffffffdd !important;
  padding-bottom: 12px;
  padding-left: 2px;
}

.main_headings_details {
  font: 700 60px "Kanit", sans-serif;
  
  color: #fff;
}
.main_headings_sub_details {
  font: 700 48px "Kanit", sans-serif;
  
  color: #fff;
}

.p_para_1_details {
  font: 300 18px "Jost", sans-serif;
  
  color: #ffffffbb;
}
.show_small
{
  display: none !important;
}
.row_reversal
{
  flex-direction: row-reverse;
}
.show_large
{
  display: block !important;
}

.get_api_page
{
  padding: 70px 0px 80px;
  min-height: 85vh;
}
.get_api_page .img_container
{
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.get_api_page .img_container img
{
  position: relative;
  margin: auto;
}
.get_api_page .the_form
{
  width: 80%;
}

@media screen and (max-width: 767px) {
  .get_api_page
  {
    padding: 32px 0px 32px;
    min-height: 85vh;
  }
  .main_headings_details {
    font: 700 40px "Kanit", sans-serif;
  }
  .main_headings_sub_details {
    font: 700 34px "Kanit", sans-serif;
    
    color: #fff;
  }

  .p_para_1_details {
    font: 300 14px "Jost", sans-serif;
  }
  .show_large
  {
    display: none !important;
  }
  .show_small
  {
    display: block !important;
  }
  .get_api_page .the_form
  {
    width: 100%;
    padding: 10px;
  }
  
}