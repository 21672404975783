
.dedi_plan_table tr th {
    border-color: #d9d9d9;
    border-style: solid;
    height: 49px;
    background-color: #e33535;
    color: #fff;
    /* color: #fbb901; */
}

.dedi_plan_table tr td {
    border-color: transparent;
    color: #d9d9d9;
    text-align: center;
    height: 49px;
}
.dedi_plan_table tr td.text_left {
    text-align: left !important;
}
.dedi_plan_table tr td:last-child {
    color: #d9d9d9;
    font-weight: 500;
    text-align: right;
}
.dedi_plan_table tr td:first-child {
    color: #d9d9d9;
    font-weight: 500;
    text-align: left;
}

/* .dedi_plan_table tr:nth-last-child(2) td {
    border-color: #979797;
    border-style: dashed;
} */

/* .dedi_plan_table tr:last-child td:last-child {
    color: #fbb901;
    font-weight: 600;
}
.dedi_plan_table tr:last-child td:first-child {
    color: #d9d9d9;
    font-weight: 600;
} */