@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600&family=Kanit:wght@600&display=swap');
.main_container
{
    min-height: 80vh;
    background-color: #111;
    width: 100%;
    padding: 100px 0px;
    
}
.text
{
    font: 500 40px 'Jost',sans-serif;
    margin-top: 20px;
    margin-bottom: 40px;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
}
.btn_solid_grad {
    display: inline-block;
    /* padding: 0.8rem 2.5rem 0.8rem 2.5rem; */
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    min-width: 200px;
    border: none;
    border-radius: 2.5rem;
    background-color: #E33535;
    font: 600 0.8rem/1.2rem "Kanit", sans-serif;
    letter-spacing: 1px;
    text-align: center;
    text-decoration: none !important;
    transition: all 0.2s;
    color: #f4f4f4;
  }
  
  .btn_solid_grad:hover {
    /* transform: scale(1.1); */
    background-color: #f4f4f4;
    color: 111;
  }