.rpcLocation {
    fill: #FBB901;
    fill-opacity: 1;
    fill-rule: "nonzero";
    stroke: #FBB901;
    stroke-width: 3px;
    display: inline-block;
    transition: all 0.1s ease-in-out;
}

/* .rpcLocation:hover {
    
    stroke-width: 3px;
    stroke: #FFFFFF;
    
    cursor: pointer;
} */

.rpcLocationText {
    /* opacity: 0.8; */
    transition: opacity 0.1s ease-in-out;
}

/* .rpcLocation:hover+.rpcLocationText {
    opacity: 1;

} */

/* .rpcLocationText:hover {
    opacity: 1;
    cursor: pointer;
} */
/* 
.rpcLocationText:hover+.rpcLocation {
    stroke-width: 3px;
    stroke: #FFFFFF;
    cursor: pointer;
} */

.rpcLocationText .contentContainer {
    background-color: black;
    border: 2px solid transparent;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}

.rpcLocationText .contentContainer .locationText {
    color: white;
    font: 600 20px "Jost", sans-serif;
}

.rpcLocationText .contentContainer .rpcText {
    color: #ccc;
    font: 400 15px "Jost", sans-serif;
}

/* .rpcLocation:hover+.rpcLocationText .contentContainer {
    border: 2px solid #fff;

}

.rpcLocationText:hover .contentContainer {
    border: 2px solid #fff;
} */

.map_container {
    padding-left: 0px;
    transform: scale(0.57);
    height: 770px;
}

/* @media screen and (max-width: 1440px) {
    .map_container {
        padding-left: 0px;
        transform: scale(0.50);
        height: 650px;
    }
} */
@media screen and (max-width: 1366px) {
    .map_container {
        padding-left: 0px;
        transform: scale(0.57);
        height: 770px;
    }
}

@media screen and (max-width: 1280px) {
    .map_container {
        padding-left: 0px;
        transform: scale(0.52);
        height: 710px;
    }
}

@media screen and (max-width: 1152px) {
    .map_container {
        padding-left: 30px;
        transform: scale(0.5);
        height: 600px;
    }
}

@media screen and (max-width: 1024px) {
    .map_container {
        padding-left: 0px;
        transform: scale(0.45);
        height: 540px;
    }
}

@media screen and (max-width: 892px) {
    .map_container {
        padding-left: 0px;
        transform: scale(0.36);
        height: 400px;
    }
}
@media screen and (max-width: 767px) {
    .map_container {
        display: none;
    }
}

.map_radio_container {
    display: none;
}

@media screen and (max-width: 767px) {
    .map_radio_container {
        display: block;
    }
}


@keyframes mymove {
    0%   { opacity:0.6; }
    50%  { opacity:1; }
    100% { opacity:0.6; }
}
@keyframes dotAnimation {
    0%   { stroke-width: 1px; }
    50%   { stroke-width: 3px; }
    100%   { stroke-width: 1px; }
}

.eu_ny_animation {
    animation: mymove 1.5s infinite;
}

.eu_ash_animation {
    animation: mymove 1.5s infinite;
    animation-delay: 1s;

}

.eu_mia_animation {
    animation: mymove 1.5s infinite;
    animation-delay: 2.2s;
}

.eu_ny_dot_animation {
    border: 2px solid;
    border-color: transparent;
    animation: dotAnimation 1.5s infinite;
}
.eu_ash_dot_animation {
    
    animation: dotAnimation 1.5s infinite;
    animation-delay: 1s;
}
.eu_mia_dot_animation {
    border: 2px solid;
    border-color: transparent;
    animation: dotAnimation 1.5s infinite;
    animation-delay: 2.2s;
}